import Swiper from 'swiper';

if(document.querySelector("#usp-panel.swiper")){
    let uspCarouselInit = false;
    let uspSwiper;
    let uspMobileSwiper = () => {
        if(window.innerWidth <= 990){
            if(!uspCarouselInit){
                uspCarouselInit = true;
                uspSwiper = new Swiper("#usp-panel.swiper", {					
                    slidesPerView: 1.25,
                    spaceBetween: 16,
                    breakpoints: {
                        600: {
                            slidesPerView: 2.25,
                            spaceBetween: 16,
                        },
                        800: {
                            slidesPerView: 3.25,
                            spaceBetween: 16,
                        }
                    }
                });
            }
        }else if(uspCarouselInit){
            uspSwiper.destroy();
            uspCarouselInit = false;
        }
    }
    uspMobileSwiper();
    window.addEventListener("resize", uspMobileSwiper);
}

