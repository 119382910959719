import { defineStore } from 'pinia'
import axios from 'axios'

import { Store } from '../../Scripts/Model/store-model'
import { Pte } from '../../Scripts/Model/pte-model'
import { AppointmentType } from '../../Scripts/Model/appointment-type-model'
import { AppointmentStage } from '../../Scripts/Model/appointment-stage-model'
import { AppointmentSlot } from '../../Scripts/Model/appointment-slot-model'
import { AppointmentDetails } from '../../Scripts/Model/appointment-details-model'
import { Town } from '../../Scripts/Model/town-model'

export type RootState = {

    towns: Town[],

    open: boolean;    
    stage: AppointmentStage;

    stores: Store[];
    ptes: Pte[];
    appointmentTypes: AppointmentType[];

    selectedStore: Store;
    selectedPte: Pte;
    selectedAppointmentType: AppointmentType;

    selectableDates: Date[];
    selectedDate: Date;
    calendarDate: Date;

    yourPosition: any;
    userSelectedNortherIreland: boolean,
    
    showStores: boolean,
    showPartners: boolean,

    selectableTimes: AppointmentSlot[],

    destinations: string[],

    appointmentDetails: AppointmentDetails;

    error: string;

    isLoading: boolean;
    fetchingStores: boolean;
    fetchingTowns: boolean;

    appointmentTypePixieValue: string;
    
    reCaptchaToken: string;
};

export const useAppointmentStore = defineStore("appointment", {
    state: (): RootState => ({
        open: false,
        stage: AppointmentStage.StoreList,

        towns: new Array,

        stores: new Array,
        ptes: new Array,
        appointmentTypes: new Array,

        selectedStore: null,
        selectedPte: null,
        selectedAppointmentType: null,
        selectedDate: null,

        yourPosition: null,
        userSelectedNortherIreland: false,

        selectableDates: new Array,
        selectableTimes: new Array,

        calendarDate: new Date(),

        destinations: new Array,

        appointmentDetails: new AppointmentDetails(),

        showStores: true,
        showPartners: true,

        error: null,

        isLoading: true,

        
        fetchingStores: false,
        fetchingTowns: false,

        appointmentTypePixieValue: null,

        reCaptchaToken: null
    }),
    getters: {

        getStage(state: RootState) {
            return state.stage;
        },
        getError(state: RootState) {
            return state.error;
        },

        getTowns(state: RootState) {
            return state.towns;
        },  
        getStores(state: RootState) {
            return state.stores.filter(s => (s.isPartner && state.showPartners) || !s.isPartner && state.showStores);
        },        
        getStore(state: RootState) {
            return state.selectedStore;
        },
        
        getPtes(state: RootState) {
            return state.ptes;
        },        
        getPte(state: RootState) {
            return state.selectedPte;
        },
        
        getAppointmentTypes(state: RootState) {
            return state.appointmentTypes;
        },        
        getAppointmentType(state: RootState) {
            return state.selectedAppointmentType;
        },

        getAppointmentDetails(state: RootState) {
            return state.appointmentDetails;
        },
        getLoading(state: RootState) {
            return state.isLoading;
        },
    },
    actions: {        
        releaseQs() {
            return 'r=' + (<HTMLInputElement>document.getElementById('releaseVersion')).value;
        },

        async fetchTowns() {
            if(this.fetchingTowns)
                return;
            this.fetchingTowns = true;

            try {
                const data = await axios.get(`/api/appointment/get-towns/?`+ this.releaseQs())
                this.towns = data.data
            } catch (error) {
                this.doError(error)
                console.log(error)
            }
        },

        async fetchStores() {
            if(this.fetchingStores)
                return;
            this.fetchingStores = true;

            try {
                const data = await axios.get(`/api/appointment/get-stores/?`+ this.releaseQs())
                if(this.stores.length == 0)
                    this.stores = data.data
            } catch (error) {
                this.doError(error)
                console.log(error)
            }
        },

        async fetchPtes() {
            try {
                const data = await axios.get(`/api/appointment/get-ptes/?storeId=${this.selectedStore.id}&`+ this.releaseQs());
                this.ptes = data.data
            } catch (error) {
                this.doError(error)
                console.log(error)
            }
        },

        async fetchDestinations() {
            try {
                const data = await axios.get(`/api/appointment/get-destinations/?`+ this.releaseQs())
                this.destinations = data.data
            } catch (error) {
                this.doError(error)
                console.log(error)
            }
        },
        
        async fetchAppointmentTypes() {
            try {
                const data = await axios.get(`/api/appointment/get-appointment-types/?`+ this.releaseQs())
                this.appointmentTypes = data.data
            } catch (error) {
                this.doError(error)
                console.log(error)
            }
        },

        async calculateDates() {
            try {
                const data = await axios.post(`/api/appointment/calculate-dates/`, { 
                    storeId: this.selectedStore.id, 
                    pteId: this.selectedPte?.id,
                    month: this.calendarDate.getMonth() + 1,
                    year: this.calendarDate.getFullYear()
                })
                this.selectableDates = data.data
            } catch (error) {
                this.doError(error)
                console.log(error)
            }
        },

        async calculateTimes() {
            try {
                const data = await axios.post(`/api/appointment/calculate-times/`, { 
                    storeId: this.selectedStore.id, 
                    pteId: this.selectedPte?.id,
                    date: this.selectedDate.getDate(),
                    month: this.selectedDate.getMonth() + 1,
                    year: this.selectedDate.getFullYear()
                })
                this.selectableTimes = data.data.items
            } catch (error) {
                this.doError(error)
                console.log(error)
            }
        },

        async makeAppointment(){
            try {
                const data = await axios.post(`/api/appointment/make-appointment/`, { 
                    storeId: this.selectedStore.id, 
                    pteId: this.selectedPte?.id,
                    date: this.selectedDate,
                    appointmentTypeId: this.selectedAppointmentType.id,
                    appointmentDetails: this.appointmentDetails,
                    reCaptchaToken: this.reCaptchaToken
                })

                if(!data.data.success) {                    
                    this.doError(`Sorry, we can't contact the store right now, please try calling.`);
                }
            } catch (error) {
                this.doError(error)
                console.log(error)                
            }
        },

        async presetAppointmentType(pixieValue: string) {            
            this.appointmentTypePixieValue = pixieValue;
            if (this.appointmentTypes.length == 0) {
                this.fetchAppointmentTypes().then(() => this.setAppointmentTypeByPixieValue(pixieValue));
            } else {
                this.setAppointmentTypeByPixieValue(pixieValue);
            }
        },

        setAppointmentTypeByPixieValue(pixieValue: string) {
            this.selectedAppointmentType = this.appointmentTypes.find(at => at.pixieValue == pixieValue);        
        },

        doError(errorMessage: string) {
            this.error = errorMessage;
        },

        markLocation(location: { lng: number, lat: number }){
            this.yourPosition = location;
        },
        
        setOpen(shouldOpen: boolean) {
            this.open = shouldOpen;
            if(shouldOpen) {
                document.body.classList.add('sidebar-open');
            } else {
                document.body.classList.remove('sidebar-open');
            }
        },

        setLoading(shouldLoad: boolean) {
            this.isLoading = shouldLoad;
        },

        setShowStores(show: boolean) {
            this.showStores = show;
        },

        setShowPartners(show: boolean) {
            this.showPartners = show;
        },
        
        goToStage(stage: AppointmentStage) {
            try {
                this.stage = stage;                
            } catch (error) {
                console.log(error)
            }
        },        
        
        setStoreDistance(id: number, description: string, distance: number) {
            var selectedStore = this.stores.find(s => s.id == id) as Store;
            selectedStore.distanceToUser = distance;
            selectedStore.distanceToUserDescription = description;
        },

        selectStoreById(storeId: number) {
            var chosenStore = this.stores.find(store => store.id == storeId)
            if(this.selectedStore != null && chosenStore.id == this.selectedStore.id)
                return;            

            this.resetToStore();
            this.selectedStore = chosenStore;
        },
        
        selectPteById(pteId: number) {
            if(this.selectedPte != null && pteId == this.selectedPte.id)
                return;            

            this.resetToPte();
            this.selectedPte = this.ptes.find(pte => pte.id == pteId);
        },

        selectStore(store: Store) {            
            if(this.selectedStore != null && store.id == this.selectedStore.id)
                return;            

            this.resetToStore();
            this.selectedStore = store;
        },
        
        selectPte(pte: Pte) {
            if(this.selectedPte != null && (pte != null && pte.id == this.selectedPte.id))
                return;            

            this.resetToPte();
            this.selectedPte = pte;
        },
        
        selectAppointmentType(appointmentType: AppointmentType) {
            this.selectedAppointmentType = appointmentType;
        },
        
        selectCalendarDate(date: Date) {
            this.calendarDate = date;
        },
        
        selectDate(date: Date) {
            this.selectedDate = date;
        },
        
        selectTime(time: Date) {
            this.selectedTime = time;
        },

        resetToStore(){
            this.resetToPte();
            this.selectedPte = null;
            this.ptes = [];
        },

        resetToPte(){
            this.selectedDate = null;
            this.selectedTime = null;
            this.selectedAppointmentType = null;
        }
    },
})