
<template>   
    <div class="sidebar appointment-booker" :class="{active : isOpen}" @click="setOpen(false)">
        <div class="sidebar__content" @click.stop>
            <div class="sidebar__body">
                <img @click="setOpen(false)" class="close-sidebar" src="/images/icons/cancel.svg" />

                <transition name="slide-fade" mode="out-in" @after-enter="transition">
                    <div v-if="error">
                        <div class="sidebar__header">
                            <h4>{{error}}</h4>
                            <a v-if="selectedStore" :href="'tel:'+selectedStore.phone">{{ selectedStore.phone }}</a>
                        </div>
                    </div>
                    <div class="pick-a-store" v-else-if="getStage == AppointmentStage.StoreList">
                        <div class="sidebar__header">
                            <h4>{{ cmsGlobal.FindYourStoreHeading || 'Find your nearest store' }}</h4>
                        </div>

                        <div class="store-search__input">
                            <input :placeholder="cmsGlobal.MapSearchPlaceholderText || 'Postcode or Town'"
                                   @input="updateTownFilter"
                                   @keypress="enterLocationValue"
                                   v-model="storeSelect.townOrPostCode" />
                            <div class="auto-complete-options" v-if="storeSelect.showTownsList">
                                <div v-for="town in filteredTowns" @click="selectTown(town)">
                                    {{town.townName}}<span style="color:gray;">, {{town.administrativeArea}}</span>
                                </div>
                            </div>

                            <a @click="whatsNearby"><label>{{ cmsGlobal.WhatsNearbyText || `What's nearby` }}</label><img src="/images/icons/cursor.svg" alt="cursor"></a>
                        </div>

                        <div class="filters">
                            <div class="filter-option kuoni-stores">
                                <input type="checkbox" id="storesCheckbox" name="storesCheckbox" data-type="Kuoni Store" v-model="showMarkers.stores" @change="checkStores($event)" />
                                <div class="checkbox"></div>
                                <label for="storesCheckbox" id="storesCheckbox-label">
                                    Kuoni Stores
                                    <div class="tooltip">
                                        <img src="/images/icons/info-icon.svg">
                                        <span class="tooltiptext">Plan your next holiday inside one of our beautiful stores while relaxing with a complimentary drink and chatting to our Personal Travel Experts.</span>
                                    </div>
                                </label>
                            </div>
                            <div class="filter-option partner-stores">
                                <input type="checkbox" id="partnersCheckbox" name="partnersCheckbox" data-type="Partner Store" v-model="showMarkers.partners" @change="checkPartners($event)" />
                                <div class="checkbox"></div>
                                <label for="partnersCheckbox" id="partnersCheckbox-label">
                                    Kuoni Partner Stores
                                    <div class="tooltip">
                                        <img src="/images/icons/info-icon.svg">
                                        <span class="tooltiptext">These Kuoni-branded independent travel agents work in partnership with us to offer the same service, passion and expertise we're known for.</span>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div class="store-search__body">
                             
                            <ul class="tabs">
                                <li :class="{active : !storeSelect.showMap}" @click="showList()">View List</li>
                                <li :class="{active : storeSelect.showMap}" @click="showMap()">Map</li>
                            </ul>                         
                           
                            <div class="map-wrapper" v-show="storeSelect.showMap">
                                <div id="store-select-map" ref="theMap"></div>
                            </div>
                            <div class="scrollbar-wrapper" v-show="!storeSelect.showMap">
                                <div v-if="!getLoading">
                                    <div class="store-search__store" v-for="store in stores" :key="store.id" @click="selectStore(store)">
                                        <div class="store-search__info">
                                            <small>{{ store.distanceToUserDescription }}</small>
                                            <h4>{{ store.name }}</h4>
                                            <label>{{ store.address }}</label>
                                            <label>{{ store.phone }}</label>
                                            <p>{{ store.nextAvailable }}</p>
                                            <a v-on:click.stop :href="store.link" target="_blank">View store details</a>
                                        </div>
                                        <div class="image" :style="store.imageCss"></div>
                                    </div>
                                </div>
                                <div v-else class="loading-spinner">
                                    <img src="/images/icons/loading-spinner.png" />
                                    <p>Updating results</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="getStage == AppointmentStage.ExpertSelect || getStage == AppointmentStage.DateSelect">
                        <img @click="setStage(AppointmentStage.StoreList)" class="back" src="/images/icons/chevron-right-black.svg" />
                        <div class="pick-an-expert">
                            <div class="scrollbar-wrapper">
                                <iframe :src="adjustedUrl" style="border: none; width: 100%; height: calc(100vh - 120px);" />
                            </div>
                        </div>
                    </div>

                    <div v-else-if="getStage == AppointmentStage.ThankYou">
                        <div class="pick-an-expert">
                            <div class="scrollbar-wrapper">
                                <iframe :src="'/AppointmentThankYou?storenid='+selectedStore.nid" style="border:none; width: 100%; height: calc(100vh - 120px);" />
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, reactive, ref, onMounted, watch } from 'vue';
    
    //import users store
    import { useAppointmentStore } from "../Stores/appointment-store";
    import { AppointmentStage } from '../../Scripts/Model/appointment-stage-model';
    import { Store } from '../../Scripts/Model/store-model';
    import { Town } from '../../Scripts/Model/town-model';
    import { RetailMapStyles } from '../../Scripts/map-styles';
    import { closestStore } from '../../Scripts/utils';

    import markerImage from "./../../Images/map-marker.png";
    import partnerMarkerImage from "./../../Images/partner-map-marker.png";
    
    const props = defineProps(['url'])

    const locationCache = {};

    const cmsContent = window["CmsPageContent"];
    const cmsGlobal = window["CmsGlobalContent"];
    
    // declare store variable
    const store = useAppointmentStore();

    const sureity = reactive({ isSure: true });

    const storeSelect = reactive({ 
        showMap: false, 
        townOrPostCode: '', 
        yourPosition: null, 
        yourMarker: null,
        map: null, 
        townFilter: '',
        showTownsList: false,
        infoWindow: null
    })

    const appointmentDetails = computed(() => { 
        return store.getAppointmentDetails;       
    })

    const adjustedUrl = computed(() => {
        return props.url.replaceAll('{NID}', selectedStore.value.nid);
    })

    const getStage = computed(() => {
        return store.getStage;
    })

    const error = computed(() => {
        return store.getError;
    })

    const isOpen = computed(() => {
        return store.open;
    })
    
    const selectedStore = computed(() => {
        return store.selectedStore;
    })
    
    const stores = computed(() => {
        let stores =  store.getStores.sort((a,b) => {
            if (a.distanceToUser < b.distanceToUser)
                return -1;
            
            if (a.distanceToUser > b.distanceToUser)
                return 1;
            
            return 0;
        });

        return stores;
    })
    
    const allStores = computed(() => {
        return store.stores;
    })

    const storeMarkers = [];
    const partnerMarkers = [];

    const showMarkers = reactive({ 
        stores: true, 
        partners: true 
    });

    function toggleStores(show: boolean) {
        for (var i = 0; i < storeMarkers.length; i++) {
            storeMarkers[i].setVisible(show);
        }    
    }

    function togglePartners(show: boolean) {
        for (var i = 0; i < partnerMarkers.length; i++) {
            partnerMarkers[i].setVisible(show);
        }    
    }

    function checkStores(event: Event){
        store.setShowStores(showMarkers.stores);
    }

    function checkPartners(event: Event){
        store.setShowPartners(showMarkers.partners);
    }


    const showStores = computed(() => store.showStores);
    watch(
        () => store.showStores,
        (newVal, oldVal) => {
            toggleStores(newVal);
            showMarkers.stores = newVal;
        }
    )

    const showPartners = computed(() => store.showPartners);
    watch(
        () => store.showPartners,
        (newVal, oldVal) => {
            togglePartners(newVal);
            showMarkers.partners = newVal;
        }
    )

    const ptes = computed(() => {
        return store.ptes;
    })

    const selectedPte = computed(() => {
        return store.selectedPte ?? { name: 'Anybody', image: "background-image: url(/images/icons/person.svg); background-repeat: no-repeat; background-size: 100%; border: none;"};
    })

    const filteredTowns = computed(() => {
        var filterValue = storeSelect.townFilter.toLowerCase();
        var townsList = store.getTowns.filter(t => {
            var isMatch = t.townName.toLowerCase().includes(filterValue); //|| t.administrativeArea.toLowerCase().includes(filterValue)
            return isMatch;
        });

        return (townsList.length > 0) ? townsList : [{ townName: "Search '" + storeSelect.townFilter + "'", administrativeArea: '', searchValue: storeSelect.townFilter, location: { lat:0, lng:0 } } ] as Town[];
    })

    const getLoading = computed(() => {
        return store.getLoading;
    })

    function updateTownFilter(event) { 
        storeSelect.townFilter = event.target.value;
        storeSelect.showTownsList = storeSelect.townFilter == '' ? false : true;
    }

    function setOpen(isOpen) {
        store.setOpen(isOpen);
    }

    function setLoading(boolean) {
        store.setLoading(boolean);
    }

    function setStage(stage: AppointmentStage) {
        //store.goToStage(AppointmentStage.ThankYou); //Test thankyou
        store.goToStage(stage);
    }

    async function selectStore(selectedStore: Store) {
        if(selectedStore.isPartner){
            window.kuoni.partnerForm.openSidebar(selectedStore.id, selectedStore.name);
            setOpen(false);
            return;
        }

        store.selectStore(selectedStore);
        setLoading(true);
        store.fetchPtes().then(() => { setLoading(false) });
        setStage(AppointmentStage.ExpertSelect);
    }

    async function selectTown(town){
        if (!!town.searchValue) {
            storeSelect.townOrPostCode = town.searchValue;
        } else {
            storeSelect.townOrPostCode = town.townName + ", " + town.administrativeArea; 
        }        
        storeSelect.showTownsList = false;

        geocodeInput();
    }

    async function whatsNearby() {
        showMap();
        navigator.geolocation.getCurrentPosition((x) => {            
            markLocation({ lat: x.coords.latitude, lng: x.coords.longitude }); 
        });
    }

    function enterLocationValue(event) {
        if (event.key === "Enter") {
            geocodeInput();
            storeSelect.showTownsList = false;
        }
    }

    function geocodeInput() {        
        if(locationCache[storeSelect.townOrPostCode] != null){
            markLocation(locationCache[storeSelect.townOrPostCode]);           
        } else {            
            var geoService = new google.maps.Geocoder();
            geoService.geocode({
                address: storeSelect.townOrPostCode + ", UK"
            }).then((geoResponse) =>{
                let addressComponents = geoResponse.results[0].address_components;
                locationCache[storeSelect.townOrPostCode] = {
                    lat: geoResponse.results[0].geometry.location.lat(),
                    lng: geoResponse.results[0].geometry.location.lng(),
                    addressComponents: addressComponents
                };                    
                markLocation(locationCache[storeSelect.townOrPostCode]); 
                if(addressComponents.some(c => c.short_name == 'Northern Ireland')){
                    store.userSelectedNortherIreland = true;
                } else {
                    store.userSelectedNortherIreland = false;
                }
            });
        }
    }

    function markLocation(location: { lat: number, lng: number }) {
        store.yourPosition = location;
        if(storeSelect.yourMarker != null){            
            storeSelect.yourMarker.setPosition(store.yourPosition);
        } else {
            storeSelect.yourMarker = new window.google.maps.Marker({
                position: store.yourPosition,
                map: storeSelect.map,
            });
        }

        getDistances();           
    }

    function getDistances() {
        let closestStorePromis = closestStore(allStores.value, store.yourPosition.lng, store.yourPosition.lat, store.setStoreDistance);
        closestStorePromis.then((closestStores: Store[]) => {
            let closestStore = closestStores[0];
            popStoreInfoWindow(closestStore, false);
            var bounds = new google.maps.LatLngBounds();
            bounds.extend({ lng: closestStore.lng, lat: closestStore.lat });
            bounds.extend(store.yourPosition);
            storeSelect.map.fitBounds(bounds, { right: 50, top: 50 });            
        });
    }

    function showMap() {
        storeSelect.showMap = true;

        // clear them
        storeMarkers.length = 0;
        partnerMarkers.length = 0;

        storeSelect.yourMarker = null;

        const mapOptions = {
            center: {
                lat: 0,
                lng: 0
            },
            zoom: 4,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.LEFT_BOTTOM,
                mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN],
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            fullscreenControl: true,
            zoomControl: true,
            styles: RetailMapStyles
        };

        storeSelect.map = new google.maps.Map(document.getElementById("store-select-map"), mapOptions);
        storeSelect.infoWindow = storeSelect.infoWindow || new window.google.maps.InfoWindow({ });

        google.maps.event.addListenerOnce(storeSelect.map, "tilesloaded", () => document.querySelectorAll(`.gm-style div[role='button'] img[draggable='false']`).forEach(x => x.addEventListener("touchstart", (e) => { e.stopImmediatePropagation() })));

        var bounds = new google.maps.LatLngBounds();
        store.stores.forEach(s => {
                
            let storePosition = { lng: s.lng, lat: s.lat };
            s.appointmentMarker = new window.google.maps.Marker({
                position: storePosition,
                map: storeSelect.map,
                icon: s.isPartner ? partnerMarkerImage : markerImage
            });

            if (s.isPartner) {
                partnerMarkers.push(s.appointmentMarker);
            } else {
                storeMarkers.push(s.appointmentMarker);
            }

            //marker popups
            s.infoWindowContent =
                `<div class="store-popup">
                <div class="store-popup__info">
                    <h5>${s.name}</h5>
                    <p>${s.address}</p>
                    <a id="ap-map-info" href="javascript:void(0);">Select Store</a>
                </div>
                <div class="store-popup__image">
                    <div class="image" style="height: 100%;${s.imageCss}"></div>
                </div>
            </div>`;

            s.appointmentMarker.addListener("click", e => {
                e.domEvent.stopPropagation();
                popStoreInfoWindow(s);
            });

            bounds.extend(storePosition);

            if(store.yourPosition != null){
                markLocation(store.yourPosition);
                bounds.extend(store.yourPosition);
            }

            storeSelect.map.fitBounds(bounds);
        });

        toggleStores(showMarkers.stores);
        togglePartners(showMarkers.partners);
    }

    function popStoreInfoWindow(passedStore, shouldFocus = true) {
        if (shouldFocus)
            storeSelect.map.panTo({lat: passedStore.lat, lng: passedStore.lng });
        
        storeSelect.infoWindow.setContent(passedStore.infoWindowContent);
        storeSelect.infoWindow.open({
            map: storeSelect.map,
            anchor: passedStore.appointmentMarker,
            shouldFocus: true
        });
        window.setTimeout(() => {
            document.getElementById('ap-map-info').addEventListener("click", () => selectStore(passedStore));
        }, 0);        
    }

    function transition(){
        if (getStage.value == AppointmentStage.StoreList && storeSelect.showMap) {
            showMap();
        }   
    }

    function showList() {
        storeSelect.showMap = false;
    }

    onMounted(() => {
        Promise.all([store.fetchStores(), store.fetchTowns()]).then(() => { setLoading(false); });
    })
</script>