import Swiper, { Navigation, Pagination, Autoplay, Mousewheel, FreeMode, Thumbs } from 'swiper';
import { createApp, nextTick, defineAsyncComponent } from 'vue';
import { DatePicker } from 'v-calendar';
import SearchService from './search-service';
import 'v-calendar/dist/style.css';
import { debounce }  from 'lodash'
import { searchDate } from './utils'

import { useSearchStore } from '../Vue/Stores/search-store';

const StickyFooter = defineAsyncComponent(() => import("../Vue/Components/StickyFooter.vue"));
const QuoteModal = defineAsyncComponent(() => import("../Vue/Components/QuoteModal.vue"));
const PriceRangeSlider = defineAsyncComponent(() => import("../Vue/Components/PriceRangeSlider.vue"));
const CardFilters = defineAsyncComponent(() => import("../Vue/Components/card-filters.vue"));

// store can be used from anywhere so stash on window
declare global {
    interface Window {
        searchPiniaInstance: any,
    }
}

const defaultRoom = { adults: 2, childAges: [] };

//---------------------------------------------------------------

const stickyFooter = createApp({}); 
stickyFooter.component('stickyFooter', StickyFooter);
stickyFooter.component('v-date-picker', DatePicker);
stickyFooter.component('quote', QuoteModal);
stickyFooter.use(window.pinia);
stickyFooter.mount("[sticky-footer]");

//---------------------------------------------------------------

var buttons = document.querySelectorAll("[vue-search-trigger]");
for (var i = 0; i < buttons.length; i++) {
    let el = buttons[i];
    const trigger = createApp({
        setup() {
            const store = useSearchStore();
            return { store };
        },
        computed: {
            isOpen() {
                return this.store.getOpenModal;
            }
        },
        methods: {        
            openModal() {            
                this.store.setOpenModal(true);
            },       
            closeModal() {            
                this.store.setOpenModal(false);
            }       
        }
    });
    trigger.use(window.pinia);
    trigger.mount(el);
}

//---------------------------------------------------------------

var buttons = document.querySelectorAll("[vue-search-footer-edit]");
for (var i = 0; i < buttons.length; i++) {
    let el = buttons[i];
    const trigger = createApp({
        setup() {
            const store = useSearchStore();
            return { store };
        },
        computed: {
            isEdit() {
                return !this.store.getFooterSearchComplete;
            }
        },
        methods: {        
            edit() {      
                this.store.setFooterSearching(true);
                this.store.setFooterSearchComplete(false);
            },     
        }
    });
    trigger.use(window.pinia);
    trigger.mount(el);
}

//---------------------------------------------------------------

const app = createApp({
    name: 'main-search',
    setup() {
        const store = useSearchStore();
        store.setCanSearch();
        window.setInterval(() => store.setCanSearch(), (60 * 1000));
        return { store };
    },    
    components: {
        "price-range-slider": PriceRangeSlider,
        "card-filters": CardFilters,
    },
    created(){
        this.debounceAutocomplete = debounce(this.autocomplete, 250);
    },
    mounted() {      
    },
    data() {
        return {
            loading: false,
            loadingDetailedSearch: false,      

            destinations: [],
            products: [],
            inspiration: [],
            offers: [],

            quickSearchSubmitted: false,

            destinationNeeded: false,
            
            resultsTab: 1,
            
            searchPopupStyle: '',

            mobileFormReveal: false,

            mobileFilters: false,
            sortActive: false,

            si: null,
            counter:0
        }
    },
    watch: {
        isOpen(newState, oldState) {
            if(!newState)
                return;

            if (this.refine) {
                this.showRefineOptions();
            } else if (this.selectedResult?.isAccommodation) {
                this.searchResults = [];
                this.destinations = [];
                this.products = [];
                this.inspiration = [];
                this.offers = [];
                this.destinationNeeded = false;   
            } else if(!!this.availabilitySearchSubmitted) {
                this.hideQuickSearchResults();
                this.forceDestination();
            } else {
                this.store.setMainSearchColumn(1);
                setTimeout(() => {
                    this.$refs.searchTerm?.click();
                    this.$refs.searchTerm?.focus();
                    if (this.term.length >= 3) {
                        this.forceGetQuickResults();
                    }
                }, 0);                
            } 
        },

        mainSearchColumn(newState, oldState){
            if(oldState == 2){
                if(this.searchDate != null){
                    this.store.setShowDate(true);
                }                
            }
            if(oldState == 3){
                this.store.setShowGuests(true);
                this.store.checkChildAges(); //check all child ages have been set
            }
        },
                
        loadingDetailedSearch(newState, oldState){               
            if(oldState == true){  
                if(window.innerWidth <= 690){
                    setTimeout(() => {
                        if (this.$refs?.searchLabel?.scrollIntoView) {
                            this.$refs.searchLabel.scrollIntoView({behavior: "smooth"});
                        }                        
                    }, 10);
                }   
            }
        }      
    },
    computed: {     
        mainSearchColumn(){
            return this.store.getMainSearchColumn;
        },

        selectedResult: {
            get: function() {
                return this.store.getSelectedResult;
            },
            set: function(val) {
                this.store.setSelectedResult(val);
            }            
        },

        attributes() {
            return this.store.getSelectedDateHighlight;
        },
        disabledDates() {
            return this.store.getDisabledDates;
        },  
        specialOccasions() {
            return this.store.getOccasionOptions;
        },
        flightClasses() {
            return this.store.getFlightClassOptions;
        },
        airports() {
            let airOptions = this.store.getAirportOptions;

            if (this.selectedResult) {
                airOptions = airOptions.filter(o => {
                    let list = this.store.blockList[o.value];
                    if (!list) {
                        return true;
                    }
                    return !list.includes(this.selectedResult.id);
                });
            }            

            return airOptions;
        },
        term: {
            get() {
                return this.store.getTerm;
            },
            set(value) {
                this.store.setTerm(value)
            }
        },
        availabilityProduct: {
            get() {
                return this.store.availabilityProduct;
            },
            set(value) {
                this.store.setAvailabilityProduct(value)
            }
        },
        notAvailableMessage: {
            get() {
                return this.store.notAvailableMessage;
            },
            set(value) {
                this.store.setNotAvailableMessage(value)
            }
        },
        refine: {
            get() {
                return this.store.getRefine;
            },
            set(value) {
                this.store.setRefine(value)
            }
        },
        searchResults: {
            get() {
                return this.store.getSearchResults;
            },
            set(value) {
                this.store.setSearchResults(value)
            }
        },
        availabilitySearchSubmitted: {
            get() {
                return this.store.availabilitySearchSubmitted;
            },
            set(value) {
                this.store.setAvailabilitySearchSubmitted(value)
            }
        },
        searchDate: {
            get() {
                return this.store.getSearchDate;
            },
            set(value) {
                this.store.setSearchDate(value)
            }
        },
        searchDuration: {
            get() {
                return this.store.getSearchDuration;
            },
            set(value) {
                this.store.setSearchDuration(value)
            }
        },
        oneRoomMessageHtml() {
            return this.store.getOneRoomMessageHtml;
        },
        formattedDate() {
            return searchDate(this.searchDate);
        },
        someCriteria() {
            return (!!this.searchDate || !!this.searchDuration || !!this.guestRollup || !!this.store.selectedAirport.value) || this.store.childAgesValid && !!this.selectedResult;
        },
        allCriteria() {
            return !!this.searchDate && !!this.searchDuration && !!this.guestRollup && !!this.store.selectedAirport.value && this.store.childAgesValid && !!this.selectedResult;
        },
        guestRollup() {
            if (!this.store.showGuests) {
                return null;
            }

            let adults = this.store.passengers.map(p => p.adults).reduce(this.sum);    
            let adultText = adults > 1 ? `${adults} Adults` : `${adults} Adult`;

            let children = this.store.passengers.map(p => p.childAges.length).reduce(this.sum);
            let childText = children > 1 
                ? `and ${children} children`
                : children == 1 
                    ? `and ${children} child`
                    : '';

            let description = `${adultText} ${childText}`;

            return description;
        },
        isOpen() {
            return this.store.getOpenModal;
        },
    },
    methods: { 
        closeAllFilters(){
            this.$refs.offerCards?.closeAllFilters();
            this.$refs.productCards?.closeAllFilters();
            this.$refs.availabilityProductCards?.closeAllFilters();
        },
        showRefineOptions() {
            this.store.setMainSearchColumn(1);
            this.handleAutocompleteResults(this.searchResults);
            this.quickSearchSubmitted = false;
            this.availabilitySearchSubmitted = false; 
        },
        openModal() {            
            this.store.setOpenModal(true);
        },       
        closeModal() {            
            this.store.setOpenModal(false);
        },

        forceGetQuickResults() {
            this.clickAnyColumn(1);

            let callback = (data) => { 
                this.handleAutocompleteResults(data); 
                this.selectResult(data.destinations[0]);
            }

            SearchService.autocomplete(this.term, callback, this.handleFail); 
        },
                
        forceDestination() {
            let callback = (data) => {                 
                this.destinationNeeded = false;   
                this.selectedResult = data.destinations[0];
            }
            SearchService.autocomplete(this.term, callback, this.handleFail); 
        },

        clickSearch() {
            this.clickAnyColumn(0)
            this.availabilitySearchSubmit();
        },
        clickAnyColumn(i) {
            
            if (i > 1 && !this.selectedResult) {
                this.destinationNeeded = true;
            }
            if (i > 0 && !this.selectedResult) {
                this.store.setMainSearchColumn(1);
                this.$refs.searchTerm.click();
                this.$refs.searchTerm.focus();
            } else {
                this.store.setMainSearchColumn(i);
                if (i == 1 && !this.products) {
                    this.autocomplete();
                }
            }            

            this.resizeSearchPopup();
        },

        resizeSearchPopup() {             
            let i = this.store.getMainSearchColumn;

            if (i == 0) {
                return;
            }               
            
           if(window.innerWidth > 700){
                if(this.si != null){
                    clearInterval(this.si);
                    this.counter = 0;
                }
                this.si = setInterval(() => {
                    if(this.counter >= 3){
                        clearInterval(this.si);
                        this.counter = 0;
                    }else{
                        this.counter += 0.1; 
                        let l = this.$refs.columns.children[i-1].offsetLeft -7;
                        this.searchPopupStyle = `transform: translate(${l}px, 0);`;  
                    }                
                }, 10);
           }
        },

        sum(prev, next){
            return prev + next;
        },
                
        incDuration() {
            this.store.setIncDuration();
        },

        decDuration() {
            this.store.setDecDuration();
        },

        incAdults(room) {
            this.store.setIncAdults(room);
        },

        decAdults(room) {
            this.store.setDecAdults(room);
        },

        changeChildAge(index, room, selectedAgeIndex) {
            this.store.setChildAge(index, room, selectedAgeIndex);
            this.store.checkChildAges();
        },
        
        clearOneRoomMessage() {             
            this.store.setOneRoomMessage(true);
        },       
        
        removeRoom() {
            // Phase 1 - only one room
            this.store.setOneRoomMessage(true);
            return;

            if(this.store.passengers.length == 1)
                return;

            this.store.passengers.pop();
        },

        addRoom() {
            // Phase 1 - only one room
            this.store.setOneRoomMessage(true);
            return;

            if(this.store.passengers.length >= 3)
                return;

            this.store.passengers.push(Object.assign({}, defaultRoom));
        },

        pickDate() { 
            this.store.setShowDate(true); 
            this.mobileFormReveal = true;
            !this.guestRollup ? (this.store.setMainSearchColumn(3)) : (this.store.setMainSearchColumn(0)); //don't open next step, if its value exists
            this.resizeSearchPopup();
        },
        pickPassengers() { 
            this.store.setShowGuests(true);           
            this.store.checkChildAges(); //check all child ages have been set
            !this.store.selectedAirport.value ? (this.store.setMainSearchColumn(4)) : (this.store.setMainSearchColumn(0)); 
            this.resizeSearchPopup();
        },
        pickAirport(value) { 
            this.store.setSelectedAirport(value);
            !this.store.selectedClass.value == false ? (this.store.setMainSearchColumn(5)) : (this.store.setMainSearchColumn(0));
            this.resizeSearchPopup();
        },
        pickFlightClass(value) { 
            this.store.setSelectedClass(value);
            !this.store.selectedOccasion.value == false ? (this.store.setMainSearchColumn(6)) : (this.store.setMainSearchColumn(0));
            this.resizeSearchPopup();
        },
        pickOccasion(value) { 
            this.store.setSelectedOccasion(value);
            this.store.setMainSearchColumn(0);
            this.resizeSearchPopup();
        },

        getOptions() {            
            SearchService.options(this.handleOptions, this.handleFail);
        },

        handleAvailabilityResults(data){
            this.loading = false; 
            this.loadingDetailedSearch = false;
            if (data.refine) {             
                this.refine = true;
                this.searchResults = data;
                this.showRefineOptions();
            } else {
                this.store.youMayAlsoLike = data.youMayAlsoLike;
                this.notAvailableMessage = data.notAvailableMessage;
                this.availabilityProduct = data.availabilityProduct;
            }            
        },

        availabilitySearchSubmit() {
            if(this.allCriteria){
                this.loading = true;
                this.loadingDetailedSearch = true;
                this.hideQuickSearchResults();
                this.availabilitySearchSubmitted = true;
                if (this.selectedResult.isAccommodation) {                    
                    SearchService.accommodationAvailability(this.searchDate, this.searchDuration, this.store.selectedAirport.value, this.selectedResult.id, this.store.passengers, this.store.selectedClass?.value, this.store.selectedOccasion?.value, this.handleAccommodationAvailability, this.handleFail);                    
                } else {
                    SearchService.availability(this.searchDate, this.searchDuration, this.store.selectedAirport.value, this.selectedResult.id, this.store.passengers, this.store.selectedClass?.value, this.store.selectedOccasion?.value, this.handleAvailabilityResults, this.handleFail);
                }                
            }else{        
                if(this.selectedResult == null){ //no value in where box
                    this.destinationNeeded = true;
                }
                
                else if(this.searchDate == null){
                    this.store.setMainSearchColumn(2);
                }

                else if(this.store.showGuests == false){
                    this.store.setMainSearchColumn(3); 
                }                         
                
                else if(this.store.selectedAirport.value == null){
                    this.store.setMainSearchColumn(4);
                } 

                else if(this.store.passengers[0].childAges.length > 0){
                    this.store.passengers.forEach(room => { //forEach is assuming more rooms in future?
                        if(room.childAges.includes(null)){
                            this.store.setMainSearchColumn(3); //a child has been added with no age selected, so open pax popup 
                        }
                    })
                }    

                this.resizeSearchPopup();
            }
        },

        selectYouMayAlsoLike(item) {
            this.term = item.title;    
                        
            var handleStealthAutocomplete = (data) => {
                this.searchResults = data;
                this.destinations = data.destinations;
                this.products = data.product;
                this.inspiration = data.inspiration;
                this.offers = data.offers;
            };

            SearchService.autocomplete(this.term, handleStealthAutocomplete, this.handleFail);
            this.store.setSelectedResult(item);  
            this.availabilitySearchSubmit();
        },
        
        accommodationNotAvailable(data) {
            this.loading = false;            
            this.notAvailableMessage = data.notAvailableMessage;
            this.store.setTerm((<HTMLInputElement>document.querySelector('#accommodationName'))?.value);  
            this.store.setMainSearchColumn(0);
            this.store.setAvailabilitySearchSubmitted(true);
            this.store.setSearchResults([]);
            this.store.availabilityProduct = [];
            this.store.youMayAlsoLike = data.youMayAlsoLike;
            this.store.setSelectedResult({
                isAccommodation: true,
                id: +(<HTMLInputElement>document.querySelector('#accommodationId'))?.value
            });
            this.store.setOpenModal(true);
        },

        handleAccommodationAvailability(data) {
            if (!data.availabilityProduct[0])
                return this.accommodationNotAvailable(data);

            var resultLinkParams = new URLSearchParams(new URL(window.location.protocol + '//' + window.location.host + data.availabilityProduct[0].cardLink).search);
            var currentSearchParams = new URLSearchParams(window.location.search);
            resultLinkParams.forEach((value, key) => {
                currentSearchParams.set(key, value);
            });
            
            window.location.href = data.accommodationUrl + '?' + currentSearchParams.toString();                    
        },

        quickSearchSubmit() {            
            if (this.term.length < 3) 
                return;

            this.showQuickSearchResults();
        },

        selectResult(item) {     
            this.clickAnyColumn(0);   
            this.destinationNeeded = false;   
            this.selectedResult = item;
            this.store.setTerm(item.title);
            this.loading = true;

            this.availabilitySearchSubmitted = false;

            if (this.refine) {           
                this.refine = false;
                this.searchResults = [];
                this.destinations = [];
                this.products = [];
                this.inspiration = [];
                this.offers = [];
                this.store.youMayAlsoLike = [];
                this.availabilitySearchSubmit();
            } else {              
                SearchService.specificItem(item.id, item.destinationType, this.handleSelectResults, this.handleFail);
            }
        },

        handleSelectResults(data){
            console.log('Select Results', data);
            this.loading = false;
            this.loadingDetailedSearch = false;
            this.searchResults = data;
            this.destinations = data.destinations;
            this.products = data.product;
            this.inspiration = data.inspiration;
            this.offers = data.offers;
            this.store.teamPhoneNumber = data.teamPhoneNumber;
            this.showQuickSearchResults();
            this.store.youMayAlsoLike = [];
        },

        showQuickSearchResults() {
            this.quickSearchSubmitted = true;
            if(window.innerWidth <= 690){
                setTimeout(() => {
                    this.$refs.quickSearch.scrollIntoView({behavior: "smooth"});
                }, 10);      
            }      
            nextTick(() => {
                new Swiper(".search-destinations-cards__swiper", {
                    slidesPerView: "auto",
                    spaceBetween: 16,
                    navigation: {
                        nextEl: ".swiper-button-next__search-dest",
                        prevEl: ".swiper-button-prev__search-dest",
                    },  
                    // breakpoints: {
                    //     580: {
                    //         slidesPerView: "auto",
                    //         spaceBetween: 13,
                    //         allowTouchMove: true,
                    //         noSwiping: false,                     
                    //         navigation: {
                    //             nextEl: `.swiper-button-next__search-dest`,
                    //             prevEl: `.swiper-button-prev__search-dest`,
                    //         },
                    //     }
                    // },                
                });

                new Swiper(".tabs-swiper-search", { 
                    slidesPerView: "auto",  
                    spaceBetween: 10, 
                    freeMode:true,           
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        500: {
                            slidesPerView: "auto",  
                            spaceBetween: 10,                     
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                        },
                        800: {
                            slidesPerView: "auto",  
                            spaceBetween: 10,                               
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                        }
                    },         
                });  
            });
        },

        hideQuickSearchResults() {
            this.quickSearchSubmitted = false;          
        },

        handleFail(request){
            this.loading = false;
            this.loadingDetailedSearch = false;
            console.log('Fail', request);            
            nextTick(() => {
                this.resizeSearchPopup();
            });
        },

        handleAutocompleteResults(data){
            this.loading = false;
            this.loadingDetailedSearch = false;
            this.searchResults = data;
            this.destinations = data.destinations;
            this.products = data.product;
            this.inspiration = data.inspiration;
            this.offers = data.offers;
            nextTick(() => {
                this.resizeSearchPopup();
            });
        },

        //mobile v-model update fix
        compositionUpdate: function(event){
            this.term = event.data;
        },

        showQuick() {            
            if (this.term.length >= 3) {
                this.showQuickSearchResults();             
                nextTick(() => {
                    this.clickAnyColumn(0);
                });                 
            }
        },

        autocomplete(e: KeyboardEvent){            
            this.refine = false;
            this.hideQuickSearchResults();
            if (this.term.length >= 3) {
                if (e.keyCode == 13) {
                    this.showQuick();
                } else {
                    this.loading = true;
                    this.selectedResult = null;
                    SearchService.autocomplete(this.term, this.handleAutocompleteResults, this.handleFail);
                }                
            } else {
                this.searchResults = [];
                this.destinations = [];
                this.products = [];
                this.inspiration = [];
                this.offers = [];
                this.store.youMayAlsoLike = [];
                this.selectedResult = null;
                nextTick(() => {
                    this.resizeSearchPopup();
                });
            }            
        },
        debounceAutocomplete: () => { console.log('Searching too soon.') },         
    }
});

app.use(window.pinia);
app.component("v-date-picker", DatePicker);
app.mount("[vue-search-form]");

//---------------------------------------------------------------

window.searchPiniaInstance = useSearchStore();