<template>
    <div>
        <div class="store-search__input">
            <div>
                <input :placeholder="cmsGlobal.MapSearchPlaceholderText || 'Postcode or Town'"
                    @input="updateTownFilter"
                    @keypress="enterLocationValue"
                    v-model="storeSelect.townOrPostCode" />
                <div class="auto-complete-options" v-if="storeSelect.showTownsList">
                    <div v-for="town in filteredTowns" @click="selectTown(town)">
                        {{town.townName}}<span style="color:gray;">, {{town.administrativeArea}}</span>
                    </div>
                </div>

                <small @click="whatsNearby"><label>{{ cmsGlobal.WhatsNearbyText || `What's nearby?` }}</label><img src="/images/icons/cursor.svg" alt="cursor" /></small>
            </div>
        </div>
        <div class="filters">
            <div class="filter-option kuoni-stores">
                <input type="checkbox" id="storesCheckbox" name="storesCheckbox" data-type="Kuoni Store" v-model="showMarkers.stores" @change="checkStores($event)" />
                <div class="checkbox"></div>
                <label for="storesCheckbox" id="storesCheckbox-label">
                    Kuoni Stores
                    <div class="tooltip">
                        <img src="/images/icons/info-icon.svg">
                        <span class="tooltiptext">Plan your next holiday inside one of our beautiful stores while relaxing with a complimentary drink and chatting to our Personal Travel Experts.</span>
                    </div>
                </label>
            </div>
            <div class="filter-option partner-stores">
                <input type="checkbox" id="partnersCheckbox" name="partnersCheckbox" data-type="Partner Store" v-model="showMarkers.partners" @change="checkPartners($event)" />
                <div class="checkbox"></div>
                <label for="partnersCheckbox" id="partnersCheckbox-label">
                    Kuoni Partner Stores
                    <div class="tooltip">
                        <img src="/images/icons/info-icon.svg">
                        <span class="tooltiptext">These Kuoni-branded independent travel agents work in partnership with us to offer the same service, passion and expertise we're known for.</span>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, reactive, onMounted, watch } from 'vue';

    //import users store
    import { useAppointmentStore } from "../Stores/appointment-store";
    import { Town } from '../../Scripts/Model/town-model';

    const locationCache = {};

    const cmsContent = window["CmsPageContent"];
    const cmsGlobal = window["CmsGlobalContent"];

    // declare store variable
    const store = useAppointmentStore();

    const storeSelect = reactive({ 
        townOrPostCode: '', 
        townFilter: '',
        showTownsList: false 
    })
    
    const showMarkers = reactive({ 
        stores: true, 
        partners: true 
    });

    function checkStores(event: Event){
        store.setShowStores(showMarkers.stores);
    }

    function checkPartners(event: Event){
        store.setShowPartners(showMarkers.partners);
    }

    const showStores = computed(() => store.showStores);
    watch(
        () => store.showStores,
        (newVal, oldVal) => {
            showMarkers.stores = newVal;
        }
    )

    const showPartners = computed(() => store.showPartners);
    watch(
        () => store.showPartners,
        (newVal, oldVal) => {
            showMarkers.partners = newVal;
        }
    )
    const filteredTowns = computed(() => {
        var filterValue = storeSelect.townFilter.toLowerCase();
        var townsList = store.getTowns.filter(t => {
            var isMatch = t.townName.toLowerCase().includes(filterValue); // || t.administrativeArea.toLowerCase().includes(filterValue);            
            return isMatch;
        });

        return (townsList.length > 0) ? townsList : [{ townName: "Search '" + storeSelect.townFilter + "'", administrativeArea: '', searchValue: storeSelect.townFilter, location: { lat: 0, lng: 0 } }] as Town[];
    })

    function updateTownFilter(event) { 
        storeSelect.townFilter = event.target.value;        
        storeSelect.showTownsList = storeSelect.townFilter == '' ? false : true;
    }

    async function selectTown(town){
        if (!!town.searchValue) {
            storeSelect.townOrPostCode = town.searchValue;
        } else {
            storeSelect.townOrPostCode = town.townName + ", " + town.administrativeArea;
        } 
        storeSelect.showTownsList = false;

        geocodeInput();
    }

    async function whatsNearby() {
        navigator.geolocation.getCurrentPosition((x) => {            
            store.markLocation({ lat: x.coords.latitude, lng: x.coords.longitude }); 
        });
    }

    function enterLocationValue(event) {
        if (event.key === "Enter") {
            geocodeInput();
            storeSelect.showTownsList = false;
        }
    }

    function geocodeInput() {    
        if(locationCache[storeSelect.townOrPostCode] != null){
            store.markLocation(locationCache[storeSelect.townOrPostCode]);           
        } else {            
            var geoService = new google.maps.Geocoder();
            geoService.geocode({
                address: storeSelect.townOrPostCode + ", UK"
            }).then((geoResponse) =>{
                let addressComponents = geoResponse.results[0].address_components;

                locationCache[storeSelect.townOrPostCode] = {
                    lat: geoResponse.results[0].geometry.location.lat(),
                    lng: geoResponse.results[0].geometry.location.lng(),
                    addressComponents: addressComponents
                };                    
                store.markLocation(locationCache[storeSelect.townOrPostCode]); 
                if(addressComponents.some(c => c.short_name == 'Northern Ireland')){
                    store.userSelectedNortherIreland = true;
                } else {
                    store.userSelectedNortherIreland = false;
                }
            });
        }
    }

    onMounted(() => {
        store.fetchTowns();
    })
</script>