let isTransferPage = window.location.pathname.indexOf('transfer-out') > -1;

// function that shows the modal and is called again to navigate when visited is true
function popContent(ev: MouseEvent, time: number, elContent: HTMLElement, elLink: HTMLAnchorElement) {
    if (elLink.dataset.visited) {
        return true;
    }

    ev?.preventDefault();
    elLink.dataset.visited = "true";

    elContent.classList.add('active');
    window.setTimeout(() => {
        elLink.click(); // next run through will catch the data-visited and navigate (done this way to respect target _blank etc)
    }, time);

    return false;
}

function SomethingClicked(ev: MouseEvent) {
    let clicked = ev.target as HTMLElement;
    if (clicked.tagName == "A") {
        let linkEl = clicked as HTMLAnchorElement;
        let contentEl = document.querySelector('.link-popup[data-content-id="' + linkEl.dataset.popupContent + '"]') as HTMLAnchorElement;
        if (contentEl && linkEl.target != '_blank') {
            return popContent(ev, +contentEl.dataset.showTime, contentEl, linkEl);
        }            
    }
    return true;
}


// global bind on click to capture dynamic <a>
document.addEventListener("click", SomethingClicked);
if (document.querySelector("[vue-search-form]")) {
    document.querySelector("[vue-search-form]").addEventListener("click", SomethingClicked);
}


// special actions for transfer-out page so that we don't loop
let els = document.querySelectorAll('[data-popup-content]');
for (var i = 0; i < els.length; i++) {
    let linkEl = els[i] as HTMLAnchorElement; 
    if (isTransferPage) {
        linkEl.removeAttribute('target'); // we are on the new tab
    }
    if (linkEl.target == '_blank') {
        linkEl.href = '/transfer-out?url=' + encodeURI(linkEl.href);
    }
}

if (isTransferPage) {
    let transferLink = document.getElementById('transferLink') as HTMLAnchorElement;
    transferLink.click();
} 