import { createApp, defineAsyncComponent  } from 'vue';
import { Pinia } from 'pinia';

const Multiselect = defineAsyncComponent(() => {    
    require('@vueform/multiselect/themes/default.css');
    return import('@vueform/multiselect');
});

import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

import 'add-to-calendar-button/assets/css/atcb.css';

import OurSuspense from '../Vue/Components/our-suspense.vue'
import AppointmentMain from '../Vue/Components/appointment-main.vue'
import ExpertsFinderAppointment from '../Vue/Components/expert-finder-appointment.vue'
import AppointmentPartner from '../Vue/Components/appointment-partner.vue'
import TownsAutocomplete from '../Vue/Components/towns-autocomplete.vue'
import StoresList from '../Vue/Components/stores-list.vue'
import { useAppointmentStore } from '../Vue/Stores/appointment-store';
import { AppointmentStage } from './Model/appointment-stage-model';

// store can be used from anywhere so stash on window
declare global {
    interface Window {
        appointmentPinia: Pinia,  
        appointmentPiniaInstance: any
    }
}

// sidebar appointment wizard in appointment-main.vue
const app = createApp({});
app.use(window.pinia);
app.component("v-date-picker", DatePicker);
app.component("our-suspense", OurSuspense);
app.component("vueform-multiselect", Multiselect);
app.component("appointment-main", AppointmentMain);
app.component("expert-finder-appointment", ExpertsFinderAppointment);
app.mount("[Appointment-booker-vue]");
// ------------------------------------------------------------------------------------------

// list and map on stores page
const storesApp = createApp({});
storesApp.use(window.appointmentPinia);
storesApp.component("towns-autocomplete", TownsAutocomplete);
storesApp.component("stores-list", StoresList);
storesApp.mount("[stores-map-vue]");
// ------------------------------------------------------------------------------------------

// floating button to trigger the main booker sidebar. Uses the inline template FloatingCtaButton.cshtml -----------
var buttons = document.querySelectorAll("[Appointment-cta-trigger-vue]");
for (var i = 0; i < buttons.length; i++) {
    let el = buttons[i];
    let triggerApp = createApp({
        setup() {
            const store = useAppointmentStore();
            return { store };
        },
        methods: {
            open(storeId, skipPteSelect) {
                window['appComponent']?.closeOnRequestModal(); 
                this.store.setOpen(true);
                if(storeId){
                    this.store.selectStoreById(storeId);

                    if (skipPteSelect) {
                        this.store.goToStage(AppointmentStage.DateSelect);
                    } else {
                        this.store.setLoading(true);
                        this.store.fetchPtes().then(() => { this.store.setLoading(false) });
                        this.store.goToStage(AppointmentStage.ExpertSelect);
                    }
                }
            },
            async openWithType(type, storeId, skipPteSelect) {
                await this.store.presetAppointmentType(type);
                this.open(storeId, skipPteSelect);
            }
        },
        computed: {
            isOpen() {
                return this.store.open;
            }
        }
    });
    triggerApp.use(window.pinia);
    triggerApp.mount(el);
}

// -------------------------------------------------------------------------------------------

// staff card trigger the above. inline template in CardCarousel.cshtml ---------------------------------------------
if(document.querySelectorAll("[Appointment-staff-card-trigger-vue]").length > 0){
    const cardTriggerApp = createApp({
        setup() {
            const store = useAppointmentStore();
            return { store };
        },
        methods: {
            async open(storeId, pteId) {
                //  user clicked a specific staff member, bypass the selection straight to date.
                if(this.store.stores == null || this.store.stores.length <= 0)
                    await this.store.fetchStores();

                this.store.selectStoreById(storeId);
                
                if(this.store.ptes == null || this.store.ptes.length <= 0)
                    await this.store.fetchPtes();
                
                this.store.selectPteById(pteId);  

                this.store.goToStage(AppointmentStage.DateSelect);                
                this.store.calculateDates();
                this.store.setOpen(true);     
            }
        },
        computed: {
            isOpen() {
                return this.store.open;
            }
        }
    });
    cardTriggerApp.use(window.appointmentPinia);
    cardTriggerApp.mount("[Appointment-staff-card-trigger-vue]");
}

// -----------------------------------------------------------------------------------------

// part of the appointment form in \Pages\Shared\Components\EnquirySidebarComponent\Default.cshtml ---------------------------------------------
const partnerDateTimeApp = createApp({});
partnerDateTimeApp.use(window.pinia);
partnerDateTimeApp.component("v-date-picker", DatePicker);
partnerDateTimeApp.component("appointment-partner", AppointmentPartner);
partnerDateTimeApp.mount("[appointment-partner-vue]");
// -----------------------------------------------------------------------------------------

window.appointmentPiniaInstance = useAppointmentStore();