<template>
    <div class="scroll-wrapper">
        <div class="store-search__body">

            <div class="store-search__store" v-for="store in stores">

                <div class="store-search__info" @click="clickStoreListItem($event, store)">
                    <h4 class="sans-serif">{{store.name}}</h4>
                    <label>{{store.address}}</label>
                    <label>{{store.phone}}</label>
                    <p>{{store.nextAvailable}}</p>
                    <a @click.stop :href="store.link">View store details</a>
                </div>
               <div class="image" :style="store.imageCss"></div>
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, reactive, onMounted, watch } from 'vue';

    import markerImage from "./../../Images/map-marker.png";
    import partnerMarkerImage from "./../../Images/partner-map-marker.png";

    //import users store
    import { useAppointmentStore } from "../Stores/appointment-store";
    import { RetailMapStyles } from '../../Scripts/map-styles';
    import { MapWithPanOffset } from '../../Scripts/Model/map-with-panoffset';
    import { validPhoneNumberForKuoni, closestStore } from '../../Scripts/utils';

    const cmsContent = window["CmsPageContent"];
    const cmsGlobal = window["CmsGlobalContent"];
    
    // declare store variable
    const store = useAppointmentStore();

    onMounted(() => {
        window['mapInit'] = () => showMap();
        store.fetchStores();
    })
    
    const storeMarkers = [];
    const partnerMarkers = [];

    const storeSelect = reactive({
        yourMarker: null,
        map: null,
        infoWindow: null
    })

    const stores = computed(() => {
        let stores = store.getStores.sort((a,b) => {
            if (a.distanceToUser < b.distanceToUser)
                return -1;
            
            if (a.distanceToUser > b.distanceToUser)
                return 1;
            
            return 0;
        });

        return stores;
    })

    const allStores = computed(() => {
        return store.stores;
    })

    const yp = computed(() => store.yourPosition);
    watch(
        () => store.yourPosition,
        (newVal, oldVal) => {
            markLocation(newVal);
        }
    )
    
    const showStores = computed(() => store.showStores);
    watch(
        () => store.showStores,
        (newVal, oldVal) => {
            toggleStores(newVal);
        }
    )

    const showPartners = computed(() => store.showPartners);
    watch(
        () => store.showPartners,
        (newVal, oldVal) => {
            togglePartners(newVal);
        }
    )

    function toggleStores(show: boolean) {
        for (var i = 0; i < storeMarkers.length; i++) {
            storeMarkers[i].setVisible(show);
        }    
    }

    function togglePartners(show: boolean) {
        for (var i = 0; i < partnerMarkers.length; i++) {
            partnerMarkers[i].setVisible(show);
        }    
    }

    function panToWithOffset (map, latlng, offsetX, offsetY) {
        var ov = new google.maps.OverlayView();
        ov.onAdd = function () {
            var proj = this.getProjection();
            var aPoint = proj.fromLatLngToContainerPixel(latlng);
            aPoint.x = aPoint.x + offsetX;
            aPoint.y = aPoint.y + offsetY;
            map.panTo(proj.fromContainerPixelToLatLng(aPoint));
        };
        ov.draw = function () { };
        ov.setMap(map);
    }

    function showMap() {
        if (storeSelect.map != null) {
            return;
        }

        var mapOptions = {
            center: { lat: -51.5072, lng: 0.1276 },
            zoom: 8,
            minZoom: 3,
            maxZoom: 20,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.LEFT_BOTTOM,
                mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN],
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: true,
            styles: RetailMapStyles
        }

        storeSelect.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        storeSelect.infoWindow = storeSelect.infoWindow || new window.google.maps.InfoWindow({ });
        
        google.maps.event.addListenerOnce(storeSelect.map, "tilesloaded", () => document.querySelectorAll(`.gm-style div[role='button'] img[draggable='false']`).forEach(x => x.addEventListener("touchstart", (e) => { e.stopImmediatePropagation() })));

        //markers    
        var bounds = new google.maps.LatLngBounds();
        store.stores.forEach(s => {

            let storePosition = { lng: s.lng, lat: s.lat };
            s.storesMarker = new window.google.maps.Marker({
                position: storePosition,
                map: storeSelect.map,
                icon: s.isPartner ? partnerMarkerImage : markerImage
            });

            if(s.isPartner){
                partnerMarkers.push(s.storesMarker);
            } else {
                storeMarkers.push(s.storesMarker);
            }                

            //marker popups
            s.infoWindowContent =
                `<div class="store-popup">
                <div class="store-popup__info">
                    <h5>${s.name}</h5>
                    <p>${s.address}</p>
                    <a href='${s.link}'>View store details</a>
                </div>
                <div class="store-popup__image">
                    <div class="image" style="height: 100%; ${s.imageCss}"></div>
                </div>
            </div>`;


            s.storesMarker.addListener("click", e => {
                e.domEvent.stopPropagation();                    
                popStoreInfoWindow(s);
            });

            bounds.extend(storePosition);

            if (store.yourPosition != null) {
                markLocation(store.yourPosition);
                bounds.extend(store.yourPosition);
            }

            // sidebar has 3 states, gone (<960), attached to the right (< 1500), stuck in the middle of the screen (> 1500)
            // below we provide padding such that all markers are not under the sidebar (or floatig header)
            var screenWidth = window.innerWidth;
            var sidebarExists = screenWidth > 960;
            var sidebarFloatingInMiddle = screenWidth > 1500;
            var rightOffset = sidebarFloatingInMiddle ? screenWidth - (((screenWidth) / 2) + 300) : 550;
            var padding = sidebarExists ? { right: rightOffset, top: 200 } : { top: 200 };
            storeSelect.map.fitBounds(bounds, padding);
        });
    }
    
    function markLocation(location: { lat: number, lng: number }) {
        if(storeSelect.yourMarker != null){            
            storeSelect.yourMarker.setPosition(store.yourPosition);
        } else {
            storeSelect.yourMarker = new window.google.maps.Marker({
                position: store.yourPosition,
                map: storeSelect.map,
            });
        }

        getDistances();         
    }

    function getDistances() {
        let closestStorePromis = closestStore(allStores.value, store.yourPosition.lng, store.yourPosition.lat, store.setStoreDistance);
        closestStorePromis.then((closestStores) => {
            let closestStore = closestStores[0];
            zoomStore(closestStore);         
        });
    }

    function zoomStore(closestStore) {
        var bounds = new google.maps.LatLngBounds();
        bounds.extend({ lng: closestStore.lng, lat: closestStore.lat });
        bounds.extend(store.yourPosition);

        if (!bounds.isEmpty()) {
            // quite different to athe appointment-main, don't refactor away the differences
            // need to massivley pad the bounds beause we have a floating thing in the middle-right. Except on mobile @ 960.
            var screenWidth = window.innerWidth;
            var sidePadding = screenWidth > 960 ? (screenWidth - document.querySelector('.container').clientWidth) / 2.0 : 0;
            sidePadding = sidePadding < 205 ? 0 : sidePadding;

            var screenWidth = window.innerWidth;
            var sidebarExists = screenWidth > 960;
            var padding = sidebarExists ? { right: sidePadding + 550, left: 0, top: 300, bottom: 100 } : { top: 350, left: 25, right: 25, bottom: 125 };

            storeSelect.map.fitBounds(bounds, padding);
        }

        popStoreInfoWindow(closestStore, false);
    }

    function clickStoreListItem(e, passedStore) {
        popStoreInfoWindow(passedStore);
        e.stopPropagation();
    } 
    
    function popStoreInfoWindow(passedStore, shouldFocus = true) {
        if(shouldFocus)
            panToWithOffset(storeSelect.map, { lat: passedStore.lat, lng: passedStore.lng }, 0, -120);

        storeSelect.infoWindow.setContent(passedStore.infoWindowContent);
        storeSelect.infoWindow.open({
            map: storeSelect.map,
            anchor: passedStore.storesMarker,
            shouldFocus: false,            
        });
    }
</script>