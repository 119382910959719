
export enum AppointmentStage {
    StoreList = 1,
    ExpertSelect = 2,
    DateSelect = 3,
    TimeSelect = 4,
    TypeSelect = 5,
    Details = 6,
    Confirm = 7,    
    ThankYou = 8
}