<template>   
    <div class="sidebar appointment-booker" :class="{active : isOpen}" v-on:click="setOpen(false)">
        <div class="sidebar__content" v-on:click.stop>
            <div class="sidebar__body">
                <img v-on:click="setOpen(false)" class="close-sidebar" src="/images/icons/cancel.svg" />

                <transition name="slide-fade" mode="out-in" @after-enter="transition">
                    <div v-if="error">
                        <div class="sidebar__header">                            
                            <h4>{{error}}</h4>                            
                            <a v-if="selectedStore" :href="'tel:'+selectedStore.phone">{{ selectedStore.phone }}</a>
                        </div>
                    </div>
                    <div class="pick-a-store" v-else-if="getStage == AppointmentStage.StoreList">
                        <div class="sidebar__header">
                            <h4>{{ injectTypeText(cmsGlobal.FindYourStoreHeading || 'Book [type] appointment') }}</h4>
                            <p class="subtitle">Find your nearest store to get started</p>
                        </div>

                        <div class="store-search__input">
                            <input :placeholder="cmsGlobal.MapSearchPlaceholderText || 'Postcode or Town'"
                                    @input="updateTownFilter"
                                    @keypress="enterLocationValue"
                                    v-model="storeSelect.townOrPostCode" />
                            <div class="auto-complete-options" v-if="storeSelect.showTownsList">
                                <div v-for="town in filteredTowns" @click="selectTown(town)">
                                    {{town.townName}}<span style="color:gray;">, {{town.administrativeArea}}</span>
                                </div>
                            </div>

                            <a @click="whatsNearby"><label>{{ cmsGlobal.WhatsNearbyText || `What's nearby` }}</label><img src="/images/icons/cursor.svg" alt="cursor"></a>
                        </div>
                        
                        <div class="filters">
                            <div class="filter-option kuoni-stores">
                                <input type="checkbox" id="storesCheckbox" name="storesCheckbox" data-type="Kuoni Store" v-model="showMarkers.stores" @change="checkStores($event)" />
                                <div class="checkbox"></div>
                                <label for="storesCheckbox" id="storesCheckbox-label">
                                    Kuoni Stores
                                    <div class="tooltip">
                                        <img src="/images/icons/info-icon.svg">
                                        <span class="tooltiptext">Plan your next holiday inside one of our beautiful stores while relaxing with a complimentary drink and chatting to our Personal Travel Experts.</span>
                                    </div>
                                </label>
                            </div>
                            <div class="filter-option partner-stores">
                                <input type="checkbox" id="partnersCheckbox" name="partnersCheckbox" data-type="Partner Store" v-model="showMarkers.partners" @change="checkPartners($event)" />
                                <div class="checkbox"></div>
                                <label for="partnersCheckbox" id="partnersCheckbox-label">
                                    Kuoni Partner Stores
                                    <div class="tooltip">
                                        <img src="/images/icons/info-icon.svg">
                                        <span class="tooltiptext">These Kuoni-branded independent travel agents work in partnership with us to offer the same service, passion and expertise we're known for.</span>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div class="store-search__body">
                             
                            <ul class="tabs">
                                <li :class="{active : !storeSelect.showMap}" @click="showList()">View List</li>
                                <li :class="{active : storeSelect.showMap}" @click="showMap()">Map</li>
                            </ul>                          
                    
                            <div class="map-wrapper" v-show="storeSelect.showMap">
                                <div id="store-select-map" ref="theMap"></div>
                            </div>
                            <div class="scrollbar-wrapper" v-show="!storeSelect.showMap">
                                <div v-if="!getLoading">
                                    <div class="store-search__store" v-for="store in stores" :key="store.id" @click="selectStore(store)">
                                        <div class="store-search__info">
                                            <small>{{ store.distanceToUserDescription }}</small>
                                            <h4>{{ store.name }}</h4>
                                            <label>{{ store.address }}</label>
                                            <label>{{ store.phone }}</label>
                                            <p>{{ store.nextAvailable }}</p>
                                            <a v-on:click.stop :href="store.link" target="_blank">View store details</a>
                                        </div>
                                        <div class="image" :style="store.imageCss"></div>
                                    </div>
                                </div>
                                <div v-else class="loading-spinner">
                                    <img src="/images/icons/loading-spinner.png" />
                                    <p>Updating results</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="getStage == AppointmentStage.ExpertSelect">
                        <img @click="setStage(AppointmentStage.StoreList)" class="back" src="/images/icons/chevron-right-black.svg" />
                        <div class="pick-an-expert">

                            <h4>{{ cmsGlobal.PickAnExpertHeading || 'Pick an expert' }}</h4>

                            <div class="any-expert" :class="{active : selectedPte.name === 'Anybody'}" @click="selectPte(null)">
                                <label><img src="/images/icons/person.svg" />Any expert, I don't mind</label>
                                <img v-if="selectedPte.name === 'Anybody'" src="/images/icons/tick-green.svg" />
                            </div>

                            <div class="scrollbar-wrapper">
                                <div class="experts" v-if="!getLoading">
                                    <div class="expert" v-for="pte in ptes" :key="pte.id" @click="selectPte(pte)" :class="{active : selectedPte === pte}">
                                        <div>
                                            <div class="expert-image" :style="pte.image"></div>
                                            <div>
                                                <label>{{ pte.name }}</label>
                                                <span>{{ pte.jobTitle }}</span>
                                                <p>{{ pte.expertText }}</p>
                                            </div>
                                        </div>
                                        
                                        <p v-if="pte.nextAvailable" class="next-available">Next available appointment {{ pte.nextAvailable }}</p>
                                    </div>
                                </div>
                                <div v-else class="loading-spinner">
                                    <img src="/images/icons/loading-spinner.png" />
                                    <p>Updating results</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pick-a-date" v-else-if="getStage == AppointmentStage.DateSelect">
                        <img @click="setStage(AppointmentStage.ExpertSelect)" class="back" src="/images/icons/chevron-right-black.svg" />
                        <div class="sidebar__header">
                            <h4>{{ cmsGlobal.DateHeading || 'When do you want to visit?' }}</h4>
                            <p>{{selectedStore.name}} <a @click="setStage(AppointmentStage.StoreList)">Edit</a></p>
                        </div>
                        <div>                            
                            <v-date-picker ref="calendar" :first-day-of-week="2" :masks="{weekdays: 'WW'}" @update:from-page="paging" v-model="calendarData.date" @dayclick="onDayClick" :available-dates="availableDatesCalendar" />
                        </div>
                    </div>

                    <div class="pick-a-time" v-else-if="getStage == AppointmentStage.TimeSelect">
                        <img @click="setStage(AppointmentStage.DateSelect)" class="back" src="/images/icons/chevron-right-black.svg" />
                        <h6>{{ cmsGlobal.TimeHeading || 'Choose a time' }}</h6>
                        <div class="scrollbar-wrapper">
                            <transition name="slide-fade" mode="out-in">                                
                                <div v-if="!getLoading && selectableTimes.length === 0" class="">
                                    <br />
                                    <br />
                                    <br />
                                    <p v-if="selectedPte.name === 'Anybody'">
                                        All our experts are busy on {{selectedDateGabsFormatted}}, please select a different
                                        <a href="javascript:void(0);" style="color: #006f84;" @click="setStage(AppointmentStage.DateSelect)"> date </a>
                                        or
                                        <a href="javascript:void(0);" style="color: #006f84;" @click="setStage(AppointmentStage.StoreList)"> store</a>.
                                    </p>
                                    <p v-else>
                                        {{selectedPte.name}} is not available on {{selectedDateGabsFormatted}}, please select a different
                                        <a href="javascript:void(0);" style="color: #006f84;" @click="setStage(AppointmentStage.ExpertSelect)"> expert</a>,
                                        <a href="javascript:void(0);" style="color: #006f84;" @click="setStage(AppointmentStage.DateSelect)"> date </a>
                                        or
                                        <a href="javascript:void(0);" style="color: #006f84;" @click="setStage(AppointmentStage.StoreList)"> store</a>.
                                    </p>
                                </div>
                                <div v-else-if="!getLoading" class="times">
                                    <div class="time" v-for="time in selectableTimes" :key="time.start" @click="selectTime(time)" :class="{active : isSelectedTime(time.start)}">
                                        <label>{{ formatTime(time.start) }}</label>
                                    </div>                                    
                                </div>
                                <div v-else class="loading-spinner">
                                    <img src="/images/icons/loading-spinner.png" />
                                    <p>Updating results</p>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div class="pick-a-type" v-else-if="getStage == AppointmentStage.TypeSelect">
                        <img @click="setStage(AppointmentStage.TimeSelect)" class="back" src="/images/icons/chevron-right-black.svg" />
                        <h6>{{ cmsGlobal.AppointmentTypeHeading || 'How would you like your appointment?' }}</h6>
                        <div class="scrollbar-wrapper">
                            <div class="types" v-if="!getLoading" >
                                <div class="type" v-for="aType in appointmentTypes" :key="aType.id" @click="selectAppointmentType(aType)" :class="{active : selectedAppointmentType?.id === aType.id}">
                                    <img :src="aType.icon" />
                                    <div>
                                        <label>{{ aType.name }}</label>
                                        <p>{{ aType.description }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="loading-spinner">
                                <img src="/images/icons/loading-spinner.png" />
                                <p>Updating results</p>
                            </div>
                        </div>
                    </div>

                    <div class="add-details" v-else-if="getStage == AppointmentStage.Details">
                        <img @click="setStage(AppointmentStage.TypeSelect)" class="back" src="/images/icons/chevron-right-black.svg" />
                        <div class="sidebar__header">
                            <h4>{{ cmsGlobal.DetailsHeading || 'Add your details' }}</h4>
                        </div>
                        <div class="scrollbar-wrapper">
                            <div v-if="!getLoading">
                                <form class="add-details-form">
                                    <div class="form-input">
                                        <label>Name</label>
                                        <input name="name" v-model="appointmentDetails.name" ref="adName" />
                                    </div>
                                    <div class="form-input">
                                        <label>Email</label>
                                        <input name="email" type="email" v-model="appointmentDetails.email" ref="adEmail" />
                                    </div>
                                    <div class="form-input">
                                        <label>Telephone</label>
                                        <input name="phone" type="tel" v-model="appointmentDetails.phone" ref="adPhone" />
                                        <small class="error"></small>
                                    </div>

                                    <div class="form-input">
                                        <label>Destination(s)</label>
                                        
                                         <div enquiry-destinations-stores>
                                            <enquiry-destinations-stores
                                                v-model="appointmentDetails.destinations"
                                                :destinations="destinations"
                                                name="storeEnquiryDetails.destinations"
                                                :required="true"
                                            />
                                        </div>
                                        
                                        <input name="destinations" type="hidden" v-model="appointmentDetails.destinations" />
                                    </div>

                                    <div class="form-input">
                                        <label>Date of travel</label>
                                        <input v-if="sureity.isSure" name="dateOfTravel" type="date" v-model="appointmentDetails.dateOfTravel" />
                                        <a v-if="sureity.isSure" class="not-sure" @click="setSure(false)" href="javascript: void(0);">I'm not sure yet</a>
                                        <input v-if="!sureity.isSure" @click="setSure(true)" name="dateOfTravel" type="text" value="I'm not sure yet" readonly />
                                    </div>

                                    <div class="form-input">
                                        <label>Travel information</label>
                                        <p>{{ cmsGlobal.DetailsText || `Tell us who is going with you, what airport you'd like to fly from and any other details we may find useful, such as if it's a special occasion.` }}</p>
                                        <textarea name="travelInfo" v-model="appointmentDetails.travelInfo"></textarea>
                                    </div>

                                    <button type="button" @click="loadAreYouSure">Submit</button>
                                </form>
                            </div>
                            <div v-else class="loading-spinner">
                                <img src="/images/icons/loading-spinner.png" />
                                <p>Updating results</p>
                            </div>
                        </div>
                    </div>

                    <div class="appointment-summary" v-else-if="getStage == AppointmentStage.Confirm">
                        <img @click="setStage(AppointmentStage.Details)" class="back" src="/images/icons/chevron-right-black.svg" />
                        <div class=sidebar__header>
                            <h4>{{ cmsGlobal.ConfirmHeading || 'Your appointment' }}</h4>
                        </div>

                        <div class="scrollbar-wrapper">
                            <div v-if="!getLoading" class="appointment-summary__details">
                                <section>
                                    <h5>WHERE<span @click="setStage(AppointmentStage.StoreList)">Edit</span></h5>
                                    <p>{{ selectedStore.name }}</p>
                                    <span>{{ selectedStore.address }}</span>
                                </section>
                                <section>
                                    <h5>WHEN <span @click="setStage(props.selectPte ? AppointmentStage.ExpertSelect : AppointmentStage.DateSelect)">Edit</span></h5>
                                    <div class="flex">
                                        <p> {{ selectedAppointmentType.name }} with {{ selectedExpertNameOrNot() }} on {{ selectedDate }} at {{ selectedTime }}</p>
                                        <div class="expert-image" :style="selectedPte.image"></div>
                                    </div>
                                </section>
                                <section class="details">
                                    <h5>YOUR DETAILS<span @click="setStage(AppointmentStage.Details)">Edit</span></h5>
                                    <p><i>Name</i><span>{{ appointmentDetails.name }}</span></p>
                                    <p><i>Email</i><span class="email-string">{{ appointmentDetails.email }}</span></p>
                                    <p><i>Telephone</i><span>{{ appointmentDetails.phone }}</span></p>
                                    <p><i>Destinations</i><span>{{ formatDestinations(appointmentDetails.destinations) }}</span></p>
                                    <p><i>Dates</i><span>{{ appointmentDetails.dateOfTravel }}</span></p>
                                    <p><i>Message</i><span>{{ appointmentDetails.travelInfo }}</span></p>
                                </section>
                                <button type="button" @click="makeAppointment">Send Appointment Request</button>
                                <p class="google-policy">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                            </div>
                            <div v-else class="loading-spinner">
                                <img src="/images/icons/loading-spinner.png" />
                                <p>Updating results</p>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="getStage == AppointmentStage.ThankYou" class="confirmation-stage">
                        <div class="scrollbar-wrapper">
                            <div v-if="!getLoading">
                                <div class="confirmation-stage__header" :style="selectedStore.imageCss"></div>
                                <div class="confirmation-stage__body">
                                    <div v-if="selectedPte.image != null && selectedPte.name != 'Anybody'" class="confirmation__pte" :style="selectedPte.image"></div>
                                    <h4>{{ cmsGlobal.ThankYouHeading || 'Thank you' }}</h4>
                                    <p v-if="selectedPte.name === 'Anybody'">Your appointment at our {{selectedStore.name}} store is confirmed for <b>{{ selectedTime }} on {{ selectedDate }}</b></p>
                                    <p v-else>Your request has been received and your appointment at our {{selectedStore.name}} store is confirmed for <b>{{ selectedTime }} on {{ selectedDate }}</b></p>
                                    <br />
                                    <br />
                                    <div class="atcb cta">
                                        {
                                        "name":"{{selectedAppointmentType.nameForCalendarSubject || selectedAppointmentType.name}} with {{selectedExpertNameOrNot()}} at {{selectedStore.name}} store",
                                        "description":"",
                                        "startDate":"{{ selectedDateFormatted }}",
                                        "endDate":"{{ selectedDateFormatted }}",
                                        "startTime":"{{ selectedTime }}",
                                        "endTime":"{{ selectedEndTime }}",
                                        "location":"{{ selectedAppointmentType.name }}",
                                        "options":[
                                        "Apple",
                                        "Google",
                                        "iCal|Outlook",
                                        "Outlook.com",
                                        "Yahoo"
                                        ],
                                        "timeZone":"Europe/London",
                                        "iCalFileName":"Reminder-Event"
                                        }
                                    </div>

                                    <!--<a href="https://my.kuoni.co.uk/mykuoni" class="cta white-button"><img src="/images/icons/suitcase.svg" />View in my Kuoni</a>-->
                                </div>
                            </div>
                            <a v-else class="loading-spinner">
                                <img src="/images/icons/loading-spinner.png" />
                                <p>Updating results</p>
                            </a>
                        </div>                          
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, reactive, ref, onMounted, watch } from 'vue';
    
    import Dayjs from 'dayjs';

    //import users store
    import { useAppointmentStore } from "../Stores/appointment-store";
    import { AppointmentStage } from '../../Scripts/Model/appointment-stage-model';
    import { Store } from '../../Scripts/Model/store-model';
    import { Pte } from '../../Scripts/Model/pte-model';
    import { AppointmentType } from '../../Scripts/Model/appointment-type-model';
    import { AppointmentSlot } from '../../Scripts/Model/appointment-slot-model';
    import { Town } from '../../Scripts/Model/town-model';
    import { RetailMapStyles } from '../../Scripts/map-styles';
    import { atcb_init } from 'add-to-calendar-button';
    import { validPhoneNumberForKuoni, isValidEmailAddressForKuoni, closestStore } from '../../Scripts/utils';

    import markerImage from "./../../Images/map-marker.png";
    import partnerMarkerImage from "./../../Images/partner-map-marker.png";

    import EnquiryDestinationsStores from '../Components/enquiry-destinations.vue';

    const props = defineProps({
        selectPte: Boolean
    })

    const locationCache = {};

    const theMap = ref(null);
    const adName = ref(null);
    const adEmail = ref(null);
    const adPhone = ref(null);

    const cmsContent = window["CmsPageContent"];
    const cmsGlobal = window["CmsGlobalContent"];
    
    // declare store variable
    const store = useAppointmentStore();

    const calendar = ref('calendar');

    const calendarData = reactive({ date: new Date });

    const sureity = reactive({ isSure: true });

    const storeSelect = reactive({ 
        showMap: false, 
        townOrPostCode: '', 
        yourPosition: null, 
        yourMarker: null,
        map: null, 
        townFilter: '',
        showTownsList: false,
        infoWindow: null
    })

    const appointmentDetails = computed(() => { 
        return store.getAppointmentDetails;       
    })

    const getStage = computed(() => {
        return store.getStage;
    })

    const error = computed(() => {
        return store.getError;
    })

    const isOpen = computed(() => {
        return store.open;
    })
    
    const selectedStore = computed(() => {
        return store.selectedStore;
    })
    
    const stores = computed(() => {
        let stores =  store.getStores.sort((a,b) => {
            if (a.distanceToUser < b.distanceToUser)
                return -1;
            
            if (a.distanceToUser > b.distanceToUser)
                return 1;
            
            return 0;
        });

        return stores;
    })
    
    const allStores = computed(() => {
        return store.stores;
    })

    const storeMarkers = [];
    const partnerMarkers = [];

    const showMarkers = reactive({ 
        stores: true, 
        partners: true 
    });

    function toggleStores(show: boolean) {
        for (var i = 0; i < storeMarkers.length; i++) {
            storeMarkers[i].setVisible(show);
        }    
    }

    function togglePartners(show: boolean) {
        for (var i = 0; i < partnerMarkers.length; i++) {
            partnerMarkers[i].setVisible(show);
        }    
    }

    function selectedExpertNameOrNot() {
        if (!selectedPte.value || selectedPte.value.name == 'Anybody') {
            return 'a travel expert';
        }
        
        return selectedPte.value.name.split(' ')[0];
    }

    function setSure(isSure) {
        sureity.isSure = isSure;
        if (isSure) {
            store.appointmentDetails.dateOfTravel = '';
        } else {
            store.appointmentDetails.dateOfTravel = "I'm not sure yet";
        }
    }

    function checkStores(event: Event){
        store.setShowStores(showMarkers.stores);
    }

    function checkPartners(event: Event){
        store.setShowPartners(showMarkers.partners);
    }


    const showStores = computed(() => store.showStores);
    watch(
        () => store.showStores,
        (newVal, oldVal) => {
            toggleStores(newVal);
            showMarkers.stores = newVal;
        }
    )

    const showPartners = computed(() => store.showPartners);
    watch(
        () => store.showPartners,
        (newVal, oldVal) => {
            togglePartners(newVal);
            showMarkers.partners = newVal;
        }
    )

    const ptes = computed(() => {
        return store.ptes;
    })

    const selectedPte = computed(() => {
        return store.selectedPte ?? { name: 'Anybody', image: "background-image: url(/images/icons/person.svg); background-repeat: no-repeat; background-size: 100%; border: none;"};
    })

    const filteredTowns = computed(() => {
        var filterValue = storeSelect.townFilter.toLowerCase();
        var townsList = store.getTowns.filter(t => {
            var isMatch = t.townName.toLowerCase().includes(filterValue); //|| t.administrativeArea.toLowerCase().includes(filterValue)
            return isMatch;
        });

        return (townsList.length > 0) ? townsList : [{ townName: "Search '" + storeSelect.townFilter + "'", administrativeArea: '', searchValue: storeSelect.townFilter, location: { lat:0, lng:0 } } ] as Town[];
    })

    const destinations = computed(() => {
        return store.destinations;
    })
    
    const selectedDateFormatted = computed(() => {
        return (Dayjs(store.selectedDate) as any).tz("Europe/London").format('YYYY-MM-DD');
    })

    const selectedDateGabsFormatted = computed(() => {
        return (Dayjs(store.selectedDate) as any).tz("Europe/London").format('DD MMMM');
    })

    const selectedDate = computed(() => {
        let val = (Dayjs(store.selectedDate) as any).tz("Europe/London").format('ddd DD MMM');
        return val == "Invalid Date" ? null : val;
    })

    const selectedTime = computed(() => {
        let val = (Dayjs(store.selectedDate) as any).tz("Europe/London").format('HH:mm');        
        return val == "Invalid Date" ? null : val;
    })

    const selectedEndTime = computed(() => {
        return (Dayjs(store.selectedDate) as any).tz("Europe/London").add(selectedAppointmentType.value.duration, 'minute').format('HH:mm');
    })

    const appointmentTypes = computed(() => {
        return store.appointmentTypes;
    })
    
    const selectedAppointmentType = computed(() => {
        return store.getAppointmentType;
    })

    const selectableDates = computed(() => {
        return store.selectableDates;
    })

    const getLoading = computed(() => {
        return store.getLoading;
    })

    const availableDatesCalendar = computed(() => {
        return [{start: '2999-12-31' }, ...selectableDates.value.map(d => { return { start: d, end: d} })];
    })

    const selectableTimes = computed(() => {
        return store.selectableTimes;
    })

    function formatDestinations(dest) {
        if(!dest)
            return '';

        return dest.join(', ');
    }

    function injectTypeText(text) {
        let insertText = selectedAppointmentType.value?.wording || 'an';
        return text.replace(/\[type\]/g, insertText);
    }

    function updateTownFilter(event) { 
        storeSelect.townFilter = event.target.value;
        storeSelect.showTownsList = storeSelect.townFilter == '' ? false : true;
    }

    function setOpen(isOpen) {
        store.setOpen(isOpen);
    }

    function setLoading(boolean) {
        store.setLoading(boolean);
    }

    function formatTime(time) {
        return (Dayjs(time) as any).tz("Europe/London").format("HH:mm");
    }

    function isSelectedTime(time) {
        return (Dayjs(time) as any).tz("Europe/London").format("HH:mm") == selectedTime.value;
    }

    function setStage(stage: AppointmentStage) {

        if (stage == AppointmentStage.StoreList) {
            pushDataLayer('storeList', 'loadStep');
        } else if (stage == AppointmentStage.ExpertSelect) {
            pushDataLayer('expertSelect', 'loadStep');
        } else if (stage == AppointmentStage.DateSelect) {
            pushDataLayer('dateSelect', 'loadStep');
        } else if (stage == AppointmentStage.TimeSelect) {
            pushDataLayer('timeSelect', 'loadStep');
        } else if (stage == AppointmentStage.TypeSelect) {
            pushDataLayer('typeSelect', 'loadStep');
        } else if (stage == AppointmentStage.Details) {
            pushDataLayer('detailsForm', 'loadStep');
        } else if (stage == AppointmentStage.Confirm) {
            pushDataLayer('details', 'loadStep');
        } else if (stage == AppointmentStage.ThankYou) {
            pushDataLayer('thankyou', 'submitted');
        }

        store.goToStage(stage);
    }

    function pushDataLayer(eventAction, eventLabel) {
        window.dataLayer.push({
            'event': 'appointment',
            'eventAction': eventAction,
            'eventLabel': eventLabel,
            'store': store.selectedStore?.name,
            'expert': store.selectedPte?.name,
            'date': selectedDate.value,
            'time': selectedTime.value,
            'type': selectedAppointmentType.value?.name,
            'details': {
                'destination': appointmentDetails.value?.destinations,
                'dateOfTravel': appointmentDetails.value?.dateOfTravel,
                'travelInfo': appointmentDetails.value?.travelInfo,
                'name': appointmentDetails.value?.name,
                'email': appointmentDetails.value?.email,
                'phone': appointmentDetails.value?.phone,
            }
        });
    }

    async function selectStore(selectedStore: Store) {
        if(selectedStore.isPartner){
            window.kuoni.partnerForm.openSidebar(selectedStore.id, selectedStore.name);
            setOpen(false);
            return;
        }

        store.selectStore(selectedStore);

        if (props.selectPte) {
            setLoading(true);
            store.fetchPtes().then(() => { setLoading(false) });
            setStage(AppointmentStage.ExpertSelect);
        } else {
            setStage(AppointmentStage.DateSelect);
        }
    }

    function selectPte(selectedPte: Pte) {
        store.selectPte(selectedPte);
        setStage(AppointmentStage.DateSelect);
    }    
    
    function onDayClick(day) {
        let dateString = day.id; // id is yyyy-MM-dd
        if(!availableDatesCalendar.value.some(d => {
            var thisDateString =  (d.start as string).substring(0, 10)
            return thisDateString == dateString;
        }))
            return;

        store.selectedDate = new Date(dateString);       
        setLoading(true);
        store.calculateTimes().then(() => { setLoading(false) });
        setStage(AppointmentStage.TimeSelect);
    }

    function paging(page) {
        var newDate = new Date(store.calendarDate);
        newDate.setMonth(page.month-1);
        newDate.setFullYear(page.year);
        store.selectCalendarDate(newDate);
        store.calculateDates();
    }

    function selectTime(time: AppointmentSlot) {
        store.selectedDate = new Date(time.start);
        setLoading(true);
        store.fetchAppointmentTypes().then(() => { setLoading(false) });
        setStage(AppointmentStage.TypeSelect);
    }

    function selectAppointmentType(selectedType: AppointmentType) {
        store.selectAppointmentType(selectedType);
        setLoading(true);
        store.fetchDestinations().then(() => { setLoading(false) });
        setStage(AppointmentStage.Details);
    }

    function validateDetails() {
        var valid = true;
        adName.value.classList.remove('invalid');
        adEmail.value.classList.remove('invalid');
        adPhone.value.classList.remove('invalid');
        let fistInvalidControl = null;

        if (!appointmentDetails.value.name) {
            valid = false;
            adName.value.classList.add('invalid');
            fistInvalidControl = adName;
        }
        if (!isValidEmailAddressForKuoni(appointmentDetails.value.email)) {
            valid = false;
            adEmail.value.classList.add('invalid');
            if(!fistInvalidControl)
                fistInvalidControl = adEmail;
        }
        if (!appointmentDetails.value.phone || !validPhoneNumberForKuoni(appointmentDetails.value.phone)) {
            valid = false;
            adPhone.value.classList.add('invalid');
            adPhone.value.nextElementSibling.innerText = 'Phone number must start with 0 or + and be 9 to 13 characters long.';
            if (!fistInvalidControl)
                fistInvalidControl = adPhone;
        } else {
            adPhone.value.nextElementSibling.innerText = '';
        }

        if(fistInvalidControl)
            fistInvalidControl.value.focus();
            
        return valid;
    }
    function loadAreYouSure() {
        if (!validateDetails()) {
            return;
        }

        setStage(AppointmentStage.Confirm);
    }

    async function makeAppointment() {
        setLoading(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute((document.getElementById('reCaptchaSharedKey') as HTMLInputElement).value, { action: 'submit' }).then((token) => {
                store.reCaptchaToken = token;
                store.makeAppointment().then(async () => {
                    setLoading(false);
                    await setStage(AppointmentStage.ThankYou);
                });   
            });
        }); 
    }

    async function selectTown(town){
        if (!!town.searchValue) {
            storeSelect.townOrPostCode = town.searchValue;
        } else {
            storeSelect.townOrPostCode = town.townName + ", " + town.administrativeArea; 
        }        
        storeSelect.showTownsList = false;

        geocodeInput();
    }

    async function whatsNearby() {
        if(!!google.maps.event.addListenerOnce) { // check maps api is loaded - will be false if consent denied
            showMap();
        }        
        navigator.geolocation.getCurrentPosition((x) => {            
            markLocation({ lat: x.coords.latitude, lng: x.coords.longitude }); 
        });
    }

    function enterLocationValue(event) {
        if (event.key === "Enter") {
            geocodeInput();
            storeSelect.showTownsList = false;
        }
    }

    function geocodeInput() {        
        if(locationCache[storeSelect.townOrPostCode] != null){
            markLocation(locationCache[storeSelect.townOrPostCode]);           
        } else {            
            var geoService = new google.maps.Geocoder();
            geoService.geocode({
                address: storeSelect.townOrPostCode + ", UK"
            }).then((geoResponse) =>{
                let addressComponents = geoResponse.results[0].address_components;
                locationCache[storeSelect.townOrPostCode] = {
                    lat: geoResponse.results[0].geometry.location.lat(),
                    lng: geoResponse.results[0].geometry.location.lng(),
                    addressComponents: addressComponents
                };                    
                markLocation(locationCache[storeSelect.townOrPostCode]); 
                if(addressComponents.some(c => c.short_name == 'Northern Ireland')){
                    store.userSelectedNortherIreland = true;
                } else {
                    store.userSelectedNortherIreland = false;
                }
            });
        }
    }

    function markLocation(location: { lat: number, lng: number }) {
        store.yourPosition = location;
        if(storeSelect.yourMarker != null){            
            storeSelect.yourMarker.setPosition(store.yourPosition);
        } else {
            storeSelect.yourMarker = new window.google.maps.Marker({
                position: store.yourPosition,
                map: storeSelect.map,
            });
        }

        getDistances();           
    }
    
    // used by 3rd party CRO agency - can be called with 2 args, an array or, a location object
    function closestStoreExternal(lng: number | any | Array<number>, lat: number) {
        if (Array.isArray(lng)) {
            lat = lng[1];
            lng = lng[0];
        } else if (typeof lng === 'object') {
            lat = lng.lat;  
            lng = lng.lng;
        }
        return closestStore(allStores.value, lng, lat, store.setStoreDistance);
    }

    function getDistances() {
        let closestStorePromis = closestStore(allStores.value, store.yourPosition.lng, store.yourPosition.lat, store.setStoreDistance);
        closestStorePromis.then((closestStores: Store[]) => {
            let closestStore = closestStores[0];
            if(storeSelect.map) {
                popStoreInfoWindow(closestStore, false);

                var bounds = new google.maps.LatLngBounds();
                bounds.extend({ lng: closestStore.lng, lat: closestStore.lat });
                bounds.extend(store.yourPosition);
                storeSelect.map.fitBounds(bounds, { right: 50, top: 50 });
            }
        });            
    }

    function showMap() {
        storeSelect.showMap = true;

        // clear them
        storeMarkers.length = 0;
        partnerMarkers.length = 0;

        storeSelect.yourMarker = null;

        const mapOptions = {
            center: {
                lat: 0,
                lng: 0
            },
            zoom: 4,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.LEFT_BOTTOM,
                mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN],
            },            
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            fullscreenControl: true,
            zoomControl: true,
            styles: RetailMapStyles
        };

        storeSelect.map = new google.maps.Map(document.getElementById("store-select-map"), mapOptions);
        storeSelect.infoWindow = storeSelect.infoWindow || new window.google.maps.InfoWindow({ });

        google.maps.event.addListenerOnce(storeSelect.map, "tilesloaded", () => document.querySelectorAll(`.gm-style div[role='button'] img[draggable='false']`).forEach(x => x.addEventListener("touchstart", (e) => { e.stopImmediatePropagation() })));

        var bounds = new google.maps.LatLngBounds();
        store.stores.forEach(s => {
                
            let storePosition = { lng: s.lng, lat: s.lat };
            s.appointmentMarker = new window.google.maps.Marker({
                position: storePosition,
                map: storeSelect.map,
                icon: s.isPartner ? partnerMarkerImage : markerImage
            });

            if (s.isPartner) {
                partnerMarkers.push(s.appointmentMarker);
            } else {
                storeMarkers.push(s.appointmentMarker);
            }

            //marker popups
            s.infoWindowContent =
                `<div class="store-popup">
                <div class="store-popup__info">
                    <h5>${s.name}</h5>
                    <p>${s.address}</p>
                    <a id="ap-map-info" href="javascript:void(0);">Select Store</a>
                </div>
                <div class="store-popup__image">
                    <div class="image" style="height: 100%;${s.imageCss}"></div>
                </div>
            </div>`;

            s.appointmentMarker.addListener("click", e => {
                e.domEvent.stopPropagation();
                popStoreInfoWindow(s);
            });

            bounds.extend(storePosition);

            if(store.yourPosition != null){
                markLocation(store.yourPosition);
                bounds.extend(store.yourPosition);
            }

            storeSelect.map.fitBounds(bounds);
        });

        toggleStores(showMarkers.stores);
        togglePartners(showMarkers.partners);
    }

    function popStoreInfoWindow(passedStore, shouldFocus = true) {
        if (shouldFocus)
            storeSelect.map.panTo({lat: passedStore.lat, lng: passedStore.lng });
        
        storeSelect.infoWindow.setContent(passedStore.infoWindowContent);
        storeSelect.infoWindow.open({
            map: storeSelect.map,
            anchor: passedStore.appointmentMarker,
            shouldFocus: true
        });
        window.setTimeout(() => {
            document.getElementById('ap-map-info').addEventListener("click", () => selectStore(passedStore));
        }, 0);        
    }

    function transition(){
        if (getStage.value == AppointmentStage.StoreList && storeSelect.showMap) {
            showMap();
        }
        makeBtn();     
    }

    function showCalendar(btn){
        setTimeout(() => {
            let target = document.querySelector("#atcb-pos-wrapper");
            if(target){
                btn.appendChild(target);
                btn.classList.add("active");
            }
        }, 20);
    }

    function hideCalendar(btn){
        let target = document.querySelector("#atcb-pos-wrapper");
        let targetBtn = document.querySelector(".atcb-button.atcb-active");
        if(target) target.remove();
        targetBtn.classList.remove("atcb-active");
        btn.classList.remove("active");
    }    

    function makeBtn(){         
        atcb_init();   
        let btn = document.querySelector(".atcb-initialized");
        if(btn){
            let sb = document.querySelector(".sidebar.appointment-booker");         
            sb.addEventListener("touchend", () => hideCalendar(btn));

            btn.addEventListener("mouseenter", () => showCalendar(btn));
            btn.addEventListener("mouseleave", () => hideCalendar(btn));
            btn.addEventListener("touchend", e => {showCalendar(btn); e.stopPropagation()});
        }
    }

    function showList() {
        storeSelect.showMap = false;
    }

    declare global {
        interface Window {
            dataLayer: Record<string, any>[];
        }
    }
    onMounted(() => {
        window.dataLayer = window.dataLayer || [];

        var utc = require('dayjs/plugin/utc');
        var timezone = require('dayjs/plugin/timezone');
        Dayjs.extend(utc);
        Dayjs.extend(timezone);
        (Dayjs as any).tz.setDefault("Europe/London")
        Promise.all([store.fetchStores(), store.fetchTowns()]).then(() => { setLoading(false); });
        
        window.kuoni = window.kuoni || {};
        window.kuoni.closestStore = closestStoreExternal;
    })
</script>