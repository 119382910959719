<template>
    <div>
        <div v-if="!getLoading">
            <label for="enquiryDetails.date" id="appointment-date">
                Date
                <span style="color:red"> *</span>
            </label>
            <v-date-picker ref="calendar" :first-day-of-week="2" :masks="{weekdays: 'WW', input: 'DD/MM/YYYY'}" @update:from-page="paging" v-model="calendarData.date" @dayclick="onDayClick" :available-dates="availableDatesCalendar">
                <template v-slot="{ inputValue, togglePopover }">
                    <div class="flex items-center">                    
                        <input
                        id="appointment-date" 
                        name="enquiryDetails.date" 
                        :value="inputValue"
                        @click="togglePopover()"
                        readonly
                        />
                    </div>
                </template>        
            </v-date-picker>

            <br />
            <br />

            <label for="enquiryDetails.time" id="appointment-time">
                Time
                <span style="color:red"> *</span>
            </label>
            <select name="enquiryDetails.time" id="appointment-time" v-model="calendarData.time">
                <option v-for="option in selectableTimes" :key="option.start" :value="formatTime(option.start)">{{ formatTime(option.start) }}</option>
            </select>
        </div>

        <div v-else class="loading-spinner">
            <img src="/images/icons/loading-spinner.png" />
            <p>Updating results</p>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { computed, reactive, ref, onMounted, watch } from 'vue';
    
    import Dayjs from 'dayjs';

    //import users store
    import { useAppointmentStore } from "../Stores/appointment-store";
    import { AppointmentSlot } from '../../Scripts/Model/appointment-slot-model';
    
    const locationCache = {};
    
    // declare store variable
    const store = useAppointmentStore();

    const calendar = ref('calendar');
    const calendarData = reactive({ date: new Date, time: new Date });

    const selectedStore = computed(() => {
        return store.selectedStore;
    })

    const selectedDateFormatted = computed(() => {
        return ((Dayjs(store.selectedDate)) as any).tz("Europe/London").format('YYYY-MM-DD');
    })

    const selectedDate = computed(() => {
        return ((Dayjs(store.selectedDate)) as any).tz("Europe/London").format('ddd DD MMM');
    })

    const selectedTime = computed(() => {
        return ((Dayjs(store.selectedDate)) as any).tz("Europe/London").format('HH:mm');
    })

    const selectableDates = computed(() => {
        return store.selectableDates;
    })

    const getLoading = computed(() => {
        return store.getLoading;
    })

    const availableDatesCalendar = computed(() => {
        return [{start: '2999-12-31' }, ...selectableDates.value.map(d => { return { start: d, end: d} })];
    })

    const selectableTimes = computed(() => {
        return store.selectableTimes;
    })

    function setLoading(boolean) {
        store.setLoading(boolean);
    }

    watch(
        () => store.selectedDate,
        (newVal, oldVal) => {
            calendarData.date = newVal;
        }
    )

    function formatTime(time) {
        return (Dayjs(time) as any).tz("Europe/London").format("HH:mm");
    }

    function isSelectedTime(time) {
        return (Dayjs(time) as any).tz("Europe/London").format("HH:mm") == selectedTime.value;
    }
    
    function onDayClick(day) {
        let dateString = day.id; // id is yyyy-MM-dd
        if(!availableDatesCalendar.value.some(d => {
            var thisDateString =  (d.start as string).substring(0, 10)
            return thisDateString == dateString;
        }))
            return;

        store.selectedDate = new Date(dateString);       
        setLoading(true);
        store.calculateTimes().then(() => { setLoading(false) });
    }

    function paging(page) {
        var newDate = new Date(store.calendarDate);
        newDate.setMonth(page.month-1);
        newDate.setFullYear(page.year);
        store.selectCalendarDate(newDate);
        store.calculateDates();
    }

    function selectTime(time: AppointmentSlot) {
        store.selectedDate = new Date(time.start);
        setLoading(true);
        store.fetchAppointmentTypes().then(() => { setLoading(false) });
    }

    onMounted(() => {
        var utc = require('dayjs/plugin/utc');
        var timezone = require('dayjs/plugin/timezone');
        Dayjs.extend(utc);
        Dayjs.extend(timezone);
        (Dayjs as any).tz.setDefault("Europe/London")
        store.fetchStores().then(() => { setLoading(false); });
    })
</script>