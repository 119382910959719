// The following messages are currently send:
//   * { category: "callback-widget", event: "widget:ready" }
//      -> triggered when the widget bundle is initialised and ready to use. The widget does not necessarily show yet, see next event
//
//   * { category: "callback-widget", event: "widget:visible" }
//      -> triggered when the widget initially shows on the page after the bundle is loaded.
//
//   * { category: "callback-widget", event: "widget:open", data: { trigger: "manual|auto" } }
//      -> triggered when the widget opens. `trigger` is set to "manual" if opened via a manual click or to "auto" if opened via the auto popup feature
//
//   * { category: "callback-widget", event: "widget:close", data: { trigger: "manual|auto" } }
//      -> triggered when the widget closes. `trigger` is set to "manual" if closed via a manual click or to "auto" if closed via the auto popup feature
//
//   * { category: "callback-widget", event: "call:requested", data: { callType: "instant" result: "request.success"} }
//      -> triggered when a visitor clicks on "Call Now" and the call request is successfully scheduled on the server
//
//   * { category: "callback-widget", event: "call:requested", data: { callType: "scheduled" action: "request.success"} }
//      -> triggered when a visitor clicks on "Schedule Callback" and the call request is successfully scheduled on the server

// NOTE: This file is written in ES5 compatible Javascript.

function isRiqMessage(message) {
    return (
      message.data &&
      message.data.category &&
      message.data.category &&
      message.data.category === "callback-widget"
    );
  }
  
  function riqMessageHandler(message) {
    if (!isRiqMessage(message)) {
      return;
    }
   
    var category = message.data.category;
    var event = message.data.event;
    var data = message.data.data;
    //console.log("RIQ> category: " + category + " | event: " + event + " | data: ", data);
    if (category === "callback-widget" && event === "widget:ready") {
      window.Responseiq.injectCssFile({
        url: "/css/response-iq-inject.css",
      });
      window.Responseiq.CallbackWidget.toggleWidgetOpenState()
    }
  }
  
  window.addEventListener("message", riqMessageHandler);