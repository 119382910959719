import { createApp } from 'vue';
import { useCtaStore } from '../Vue/Stores/cta-store';
import { useAppointmentStore } from '../Vue/Stores/appointment-store';

import './response-iq-inject.js';

// store can be used from anywhere so stash on window
declare global {
    interface Window { 
        ctaPiniaInstance: any,
    }
}

declare var Responseiq: any;
declare var LiveChatWidget: any;
declare var Genesys: Function;

// button to trigger the cta sidebar -----------
var buttons = document.querySelectorAll("[cta-sidebar-trigger-vue]");

for (var i = 0; i < buttons.length; i++) {
    let el = buttons[i];
    let triggerApp = createApp({   
        setup() {
            const ctaStore = useCtaStore();
            return { ctaStore };
        },
        created() {
        },
        data() {
            return {
            }
        },
        methods: {            
            open() {
                this.ctaStore.setOpen(true);
            },
            close() {
                this.ctaStore.setOpen(false);
            }
        }
    });    
    triggerApp.use(window.pinia);
    triggerApp.mount(el);
}

// -------------------------------------------------------------------------------------------

const els = document.querySelectorAll('[cta-sidebar-vue]');
for (var i = 0; i < els.length; i++) {
    let el = els[i];
    let app = createApp({   
        setup() {
            const ctaStore = useCtaStore();
            const appointmentStore = useAppointmentStore();

            return { ctaStore, appointmentStore };
        },     
        mounted() {
            this.$nextTick(() => {
                window.setInterval(() => {
                    this.anyChat = typeof(LiveChatWidget) != "undefined" || typeof(Genesys) != "undefined";

                    if(typeof(LiveChatWidget) != "undefined" && LiveChatWidget._h){ //their check for loaded
                        var response = LiveChatWidget.get("state");
                        this.online = response.availability == "online";
                        
                        // for the non-vue components in cms pages etc.
                        document.querySelectorAll('.staff-status').forEach(e => {
                            if (this.online) {
                                e.classList.add('online');
                                e.classList.add('cta-component__cta-button');
                                e.classList.remove('content');
                                e.innerHTML = 'Talk to an expert';
                            } else {
                                e.classList.remove('online');
                                e.classList.add('content');
                                e.classList.remove('cta-component__cta-button');
                                e.innerHTML = 'Currently unavailable';
                            }
                        });
                    }
                }, 2000);
            });     
            
            if (typeof (Genesys) != "undefined" && Genesys != null) {
                Genesys("subscribe", "Messenger.opened", () => this.genesysOpenState = true);
                Genesys("subscribe", "Messenger.closed", () => this.genesysOpenState = false);
            }
        },
        data() {
            return {
                liveChatToggler: false,
                genesysOpenState: false,
                online: true,
                anyChat: false
            }
        },
        methods: {
            close() {
                this.ctaStore.setOpen(false);
            },
            openEnquire(destinationName) {
                window.kuoni.enquire.openSidebar(null, null, destinationName);
            },
            toggleAppointment() {
                this.appointmentStore.setOpen(true);
            },
            toggleLiveChat() {
                if (typeof (LiveChatWidget) != "undefined" && LiveChatWidget._h) { //their check for loaded
                    if (LiveChatWidget.get("state").visibility != "maximized") {
                        LiveChatWidget.call("maximize");
                        this.ctaStore.setOpen(false);
                    }
                    else {
                        LiveChatWidget.call("hide");
                    }
                }

                if (typeof (Genesys) != "undefined" && Genesys != null) {
                    if (!this.genesysOpenState) {
                        Genesys("command", "Messenger.openConversation", {},
                            () => this.close(), // if resolved
                            () => { }   // if rejected
                        );
                    } else {
                        Genesys("command", "Messenger.close", {},
                            () => { },  // if resolved
                            () => { }  // if rejected                            
                        );
                    }
                }
            },
            openLiveChat() {
                if (typeof (LiveChatWidget) != "undefined" && LiveChatWidget._h) { //their check for loaded
                    if (LiveChatWidget.get("state").visibility != "maximized") {
                        LiveChatWidget.call("maximize");
                    }
                    this.ctaStore.setOpen(false);
                }
                if (typeof (Genesys) != "undefined" && Genesys != null) {
                    Genesys("command", "Messenger.openConversation", {},
                        () => this.close(), // if resolved
                        () => { }   // if rejected
                    );
                }
            },
            toggleResponseIq() {
                Responseiq.CallbackWidget.toggleWidgetOpenState();
                this.ctaStore.setOpen(false);
            }
        },
        computed: {
            open() {
                return this.ctaStore.open;
            }
        }
    });
    app.use(window.pinia);
    app.mount(el);
}


//---------------------------------------------------------------

window.ctaPiniaInstance = useCtaStore();