
class BaseService {
        
    public setUpCallbacksAndRequest(callbackSuccess: any, callbackFail: Function, url: string) {
        let innerCallback = function(ev: ProgressEvent): any {
            let data;
            try {
                data = JSON.parse(this.response);
            }
            catch(e) { console.error(e); }

            if (this.status >= 200 && this.status < 400) {
                callbackSuccess(data);
            }
            else {
                callbackFail(this);
            }
        };

        this.request(url, innerCallback);
    }
    
    public setUpCallbacksAndPost(callbackSuccess: any, data: any, callbackFail: Function, url: string) {
        let innerCallback = function(ev: ProgressEvent): any {
            let data;
            try {
                data = JSON.parse(this.response);
            }
            catch(e) { console.error(e); }

            if (this.status >= 200 && this.status < 400) {
                callbackSuccess(data);
            }
            else {
                callbackFail(this);
            }
        };

        this.post(url, data, innerCallback);
    }

    public request(url: string, callback: (this: XMLHttpRequest, ev: ProgressEvent) => any) {
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.onload = callback;
        request.send();
    }
    
    public post(url: string, data: any, callback: (this: XMLHttpRequest, ev: ProgressEvent) => any) {
        var request = new XMLHttpRequest();
        request.open('POST', url, true);
        request.onload = callback;
        request.setRequestHeader('Content-Type', 'application/json');
        request.send(JSON.stringify(data));
    }
}

export default new BaseService();