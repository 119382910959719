import { defineStore } from 'pinia'
import SearchService from '../../Scripts/search-service';
import searchService from '../../Scripts/search-service';

export type RootState = {
    unavailable: boolean,
    error: string,
    refine: boolean,

    teamPhoneNumber: string,
    oneRoomMessageHtmlTemplate: string,
    oneRoomMessage: boolean,

    mainSearchColumn: number,

    disabledDates: any,
    selectedDateHighlight: any,

    openModal: boolean,
    term: string,
    searchDate: Date,
    searchDuration: number,
    showDate: boolean,
    passengers: any,
    showGuests: boolean,
        
    optionsTriggered: boolean,

    selectedAirport: { text: string, value: string },
    selectedClass: { text: string, value: string },
    selectedOccasion: { text: string, value: string },
    childAgesValid: boolean,

    searchResults: [],
    availabilityProduct: [],    
    youMayAlsoLike: []
    availabilitySearchSubmitted: boolean,

    airportOptions: Array<{ text: string, value: string }>,
    flightClassOptions: Array<{ text: string, value: string }>,
    occasionOptions: Array<{ text: string, value: string }>,

    quoteOpen: boolean,
    footerSearching: boolean,
    footerSearchComplete: boolean,

    validationError: boolean,
    loadingNextStage: boolean,

    selectedResult: any,

    searchUnavailable: boolean,
    
    notAvailableMessage: string,

    updatedPrice: number | null,
    storeLoading:boolean 
};

export const useSearchStore = defineStore("search", {
    state: (): RootState => ({
        error: null,
        unavailable: false,
        refine: false,
        teamPhoneNumber: '',
        oneRoomMessageHtmlTemplate: `<div class="more-rooms">
    <h4>More rooms?</h4><b class="close-icon" onclick="searchPiniaInstance.oneRoomMessage=false;">X</b>
    <p>If you would like to book more than one room please call us on <a href="tel:[TELEPHONE]">[TELEPHONE]</a> or</p>
</div>  
<br />
<a href="/useful-information/help-and-support/general-enquiries/" id="pax-done" class="done a-button">Contact Us</button>`,
        oneRoomMessage: false,

        mainSearchColumn: 1,

        disabledDates: [{ end: new Date() }],
        selectedDateHighlight: [],  


        openModal: false,
        term: '',
        searchDate: null,
        searchDuration: 7,
        showDate: false,
        passengers: [ Object.assign({}, { adults: 2, childAges: [] }) ],
        showGuests: false,
        selectedAirport: { text: "Departure airports", value: null },
        selectedClass: { text: "Pick your cabin", value: null },
        selectedOccasion: { text: "Are you celebrating?", value: null },
        childAgesValid: false,

        searchResults: [],
        availabilityProduct: [],
        youMayAlsoLike: [],
        availabilitySearchSubmitted: false,

        optionsTriggered: false,
        airportOptions: [],
        flightClassOptions:[],
        occasionOptions:[],

        quoteOpen: false,

        footerSearching: false,
        footerSearchComplete: false,

        validationError: false,
        loadingNextStage: false,

        selectedResult: null,

        searchUnavailable: false,
        
        notAvailableMessage: "<p>Sorry, we couldn't match your search criteria. Please amend your search and try again.</p>",

        updatedPrice: null,
        storeLoading: false
    }),
    getters: {      
        getSelectedResult() {
            return this.selectedResult;
        },         
        getSearchResults() {
            return this.searchResults;
        },
        getRefine() {
            return this.refine;
        },
        getSelectedDateHighlight() {
            return this.selectedDateHighlight;
        },
        getDisabledDates() {
            return this.disabledDates;
        },
        getQsUnavailable() {
            let params = new URLSearchParams(window.location.search);
            if (params.has('available')) {
                return params.get('available').toLowerCase() == 'false';                
            } else {
                return false;
            }
        },
        getQsTravelArea() {
            let params = new URLSearchParams(window.location.search);
            if (params.has('bip')) {
                let json = atob(params.get('bip'));
                let bip = JSON.parse(json);
                return bip.tr;
            } else {
                return null;
            }
        },
        getQsAirport() {
            let params = new URLSearchParams(window.location.search);
            if (params.has('bip')) {
                let json = atob(params.get('bip'));
                let bip = JSON.parse(json);
                return bip.de;
            } else {
                return null;
            }
        },
        getQsFlightClass() {
            let params = new URLSearchParams(window.location.search);
            if (params.has('bip')) {
                let json = atob(params.get('bip'));
                let bip = JSON.parse(json);
                return bip.ca;
            } else {
                return null;
            }
        },
        getQsOccasion() {
            let params = new URLSearchParams(window.location.search);
            if (params.has('bip')) {
                let json = atob(params.get('bip'));
                let bip = JSON.parse(json);
                return bip.oc;
            } else {
                return null;
            }
        },
        getOpenModal() {
            return this.openModal;
        },
        getSearchDate() {
            return this.searchDate;
        },
        getSearchDuration() {
            return this.searchDuration;
        },
        getTerm() {
            return this.term;
        },
        getOneRoomMessageHtml() {
            return this.oneRoomMessageHtmlTemplate.replaceAll('[TELEPHONE]', this.teamPhoneNumber);
        },
        getOneRoomMessage() {
            return this.oneRoomMessage;
        },
        getPassengers() {
            return this.passengers;
        },
        getAirportOptions() {
            if (!this.optionsTriggerd) {
                this.optionsTriggerd = true;
                SearchService.options(this.setOptions, this.setError);
            }

            return this.airportOptions;            
        },
        getFlightClassOptions() {
            if (!this.optionsTriggerd) {
                this.optionsTriggerd = true;
                SearchService.options(this.setOptions, this.setError);
            }

            return this.flightClassOptions;            
        },
        getOccasionOptions() {
            if (!this.optionsTriggerd) {
                this.optionsTriggerd = true;
                SearchService.options(this.setOptions, this.setError);
            }

            return this.occasionOptions;            
        },
        getMainSearchColumn() {
            return this.mainSearchColumn;            
        },

        getFooterSearchComplete() {
            return this.footerSearchComplete;            
        } ,  

        getFooterSearching() {
            return this.footerSearching;            
        } ,    
        
        getValidationError() {
            return this.validationError;
        },

        getLoadingNextStage() {
            return this.loadingNextStage;
        },

        getSearchUnavailability() {
            return this.searchUnavailable;
        }
    },
    actions: {
        setCanSearch() {
            searchService.canSearch((data) => this.searchUnavailable = data.isTravelBoxDown, (error) => this.setError(error));
        },

        setSelectedDateHighlight(value) {
            let endDate = new Date(value);
            if(!value)
                return;
            endDate.setDate(value.getDate() + this.searchDuration);
            this.selectedDateHighlight = [{
                highlight: {
                    color: 'blue', //'#C1F5FF',
                    fillMode: 'light',
                },
                dates: [{ start: value, end: endDate }]
            }];
        },
        setNotAvailableMessage(value) {
            this.selectedResult = value;
        },
        setSelectedResult(value) {
            this.selectedResult = value;
        },
        setMainSearchColumn(value) {
            this.mainSearchColumn = value;
        },
        setAvailabilitySearchSubmitted(value) {
            this.availabilitySearchSubmitted = value;
            this.unavailable = false;
            let url = new URL(location.href);
            url.searchParams.delete('available');
            window.history.pushState({}, document.title, url.href);
        },
        setSearchResults(value) {
            this.searchResults = value;
        },
        setRefine(value) {
            this.refine = value;
        },
        setYouMayAlsoLike(value) {
            this.youMayAlsoLike = value;
        },
        setAvailabilityProduct(value) {
            this.availabilityProduct = value;
        },
        setOneRoomMessage(show: boolean) {
            this.oneRoomMessage = show;
        },
        setTerm(term: string) {
            this.term = term;
        },
        setOptions(data: any) {
            if (!!data.oneRoomMessageHtml && data.oneRoomMessageHtml.length > 12) {
                this.oneRoomMessageHtmlTemplate = data.oneRoomMessageHtml;
            }
            
            this.occasionOptions = data.occasions;
            this.airportOptions = data.airports;
            this.flightClassOptions = data.flightClasses;
            this.blockList = data.airportCodeBlockList;

            if (this.getQsAirport) {
                this.selectedAirport = this.airportOptions.find(a => a.value == this.getQsAirport);
            }
            if (this.getQsFlightClass) {
                let possibleFlightClass = this.flightClassOptions.find(a => a.value == this.getQsFlightClass);
                if(possibleFlightClass)
                    this.selectedClass = possibleFlightClass;
            }
            if (this.getQsOccasion) {
                let possibleOccasion = this.occasionOptions.find(a => a.value == this.getQsOccasion);
                if(possibleOccasion)
                    this.selectedOccasion = possibleOccasion;
            }
            if (this.getQsTravelArea) {
                this.term = data.travelAreaLookup.find(a => a.item2 == this.getQsTravelArea).item1;
            }

            let params = new URLSearchParams(window.location.search); // TODO: do this once, not here and in each getter
            if (params.has('bip')) {
                let json = atob(params.get('bip'));
                let bip = JSON.parse(json);
                this.searchDate = new Date(bip.da);
                this.setShowDate(true);
                this.searchDuration = bip.du;            
                this.passengers = bip.pa.map(r => <any>{ adults: r.ad, childAges: r.ch });
                this.setShowGuests(true);
                this.checkChildAges();
            } 
                        
            this.unavailable = this.getQsUnavailable;
            if (this.unavailable) {
                this.setOpenModal(true);
            }
        },
        setError(data: any) {
            this.error = data.error;
        },
        setOpenModal(open: boolean) {
            if (open) {
                window["appComponent"].lockBody();
            } else {
                window["appComponent"].unlockBody();
            }
            this.openModal = open;
        },
        setIncDuration(){
            if(this.searchDuration < 48) 
                this.searchDuration++;

            this.setSelectedDateHighlight(this.searchDate);
        },    
        setDecDuration(){
            if(this.searchDuration > 1) 
                this.searchDuration--;
            
            this.setSelectedDateHighlight(this.searchDate);
        },
        setSearchDuration(value){
            this.searchDuration = value;            
            this.setSelectedDateHighlight(this.searchDate);
        },
        setSearchDate(value){
            this.searchDate = value;
            this.setSelectedDateHighlight(value);
        },

        checkChildAges(){
            this.passengers.forEach(room => {
                if(room.childAges.includes(null)){
                    this.childAgesValid = false;
                }else{
                    this.childAgesValid = true;
                }
            });
        },

        setIncAdults(room){
            if(this.passengers[room].adults < 15) this.passengers[room].adults++;
        },
    
        setDecAdults(room){
            if(this.passengers[room].adults > 1) this.passengers[room].adults--;
        },

        setChildAge(index, room, selectedAgeIndex) {
            if(this.passengers[room].childAges[index] >= 0) this.passengers[room].childAges[index] = selectedAgeIndex;
            //console.log(this.passengers)
        },

        setShowDate(bool){
            this.showDate = bool;
        }, 
        
        setShowGuests(bool){
            this.showGuests = bool;
        }, 
        
        setSelectedAirport(value){
            this.selectedAirport = value;
        }, 

        setSelectedClass(value){
            this.selectedClass = value;
        }, 

        setSelectedOccasion(value){
            this.selectedOccasion = value;
        }, 

        setFooterSearching(value) {
            this.footerSearching = value;            
            value ? document.querySelector("body").classList.add("offset-chat") : document.querySelector("body").classList.remove("offset-chat");
        },
        setFooterSearchComplete(value) {
            this.footerSearchComplete = value;          
        },
        setValidationError(value) {
            this.validationError = value;
        },
        setLoadingNextStage(value) {
            this.loadingNextStage = value;
        },
    },
})
