<template>
    <div v-on:click.stop v-if="selectedDestinations">

        <div class="faux-select">
            <input ref="textInput" v-on:keyup="filterDestinations" class="faux-select__text" type="text" v-on:focus="toggleDropdown" placeholder="search...">
        
            <ul v-if="selectedDestinations.length != 0" ref="selected" class="faux-select__selected">
                <li v-for="destination in selectedDestinations" :key="destination">{{destination}} <img v-on:click="removeTag" src="/images/icons/cancel.svg"></li>
            </ul>

            <ul v-if="filteredDestinations.length != 0" ref="destinationsList" class="faux-select__dropdown" :class="{'active' : destinationDropdown}">
                <li v-for="destination in filteredDestinations" v-on:click="selectDestination"
                    :key="destination" 
                    :data-name="name" 
                    :data-selector="destination">
                    {{destination}}
                </li>
            </ul>

            <ul v-else class="no-destinations-found">
                <li>No Results found</li>
            </ul>
        </div>        
        
        <label class="not-sure-tag" v-on:click="notSureTag">I'm not sure yet</label>
        <input :required="required" ref="hiddenInput" type="hidden" :value="selectedDestinations.length > 0 ? 'selected' : ''" />
        <input v-for="hdi in hiddenDestinationInputs" type="hidden" :name="hdi.name" :value="hdi.text" :id="hdi.id" :key="hdi" />

    </div>     
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue'

    const props = defineProps({
        destinations: Array,
        name: String,
        required: Boolean,
        modelValue: Array
    });
    const emit = defineEmits(['update:modelValue']);

    const selectedDestinations = ref([]);
    watch(selectedDestinations, 
        async (newVal, old) => {
            emit('update:modelValue', newVal);
        },        
        { deep: true }
    );

    let filteredDestinations = ref(props.destinations);
    const destinationDropdown = ref(false);
    const textInput = ref(null);
    const selected = ref(null);
    const destinationsList = ref(null);
    const hiddenInput = ref(null);
    const hiddenDestinationInputs = ref([]);


    function filterDestinations() {
        filteredDestinations.value = props.destinations.filter(dest => dest.toLowerCase().includes(e.value.toLowerCase()));
    }

    function toggleDropdown(){
        destinationDropdown.value = !destinationDropdown.value;
    }

    function selectDestination(){
        createTag(e.textContent, e.dataset.name);
        toggleDropdown();
        destinationsList.value.addEventListener("transitionend", () => {
            filteredDestinations.value = props.destinations;
        }, {once: true});
    }

    function createTag(text, name) {
        //add/remove selected destination tag
        if(selectedDestinations.value.includes(text)){
            selectedDestinations.value = selectedDestinations.value.slice(selectedDestinations.value.indexOf(text), 1);
        }else{
            selectedDestinations.value.push(text);
        }

        //add/remove input to form for value post to server
        let toRemove = hiddenDestinationInputs.value.find(e => e.text == text);
        if(toRemove != undefined){
            hiddenDestinationInputs.value.splice(hiddenDestinationInputs.value.indexOf(toRemove), 1);
        }else{
            hiddenDestinationInputs.value.push({text: text, name: name, id: `hidden-input-${text}`});
        }
       
        textInput.value.value = ""; //empty text box
    }

    function notSureTag(){
        createTag("I'm not sure yet", "enquiryDetails.destinations");
    }

    function removeTag(){
        //remove destination tag
        selectedDestinations.value.splice(selectedDestinations.value.indexOf(e.parentElement.innerText), 1);
        
        //remove hidden tag
        let toRemove = hiddenDestinationInputs.value.find(d => d.text == e.parentElement.innerText);
        if(toRemove != undefined){hiddenDestinationInputs.value.splice(hiddenDestinationInputs.value.indexOf(toRemove), 1);}
    }

    onMounted(() => {
        let sidebar = document.querySelector(".sidebar.enquire .sidebar__content");
        sidebar.addEventListener('click', () => destinationDropdown.value = false);
    });

</script>

