import baseService from "./base-service";

class SearchService {

    hotelDetails = (id: number, callbackSuccess: (any), callbackFail: Function): any => {
        let url = '/api/search/hotel-details/?accommodationId=' + id;        
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    travelAreaDetails = (id: number, callbackSuccess: (any), callbackFail: Function): any => {
        let url = '/api/search/travel-area-details/?travelAreaId=' + id;
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    result = (id: number, callbackSuccess: (any), callbackFail: Function): any => {
        let params = new URLSearchParams(window.location.search);
        let url = '/api/search/result/?resultId=' + id + '&bip=' + params.get('bip');
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    canSearch = (callbackSuccess: (any), callbackFail: Function): any => {
        let url = '/api/search/can-search/';
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    options = (callbackSuccess: (any), callbackFail: Function): any => {
        let url = '/api/search/options/?'+ this.releaseQs();
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    autocomplete = (term: string, callbackSuccess: (any), callbackFail: Function): any => {
        let url = '/api/search/auto-complete/?query=' + encodeURIComponent(term);
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    specificItem = (id: number, destinationType, callbackSuccess: (any), callbackFail: Function): any => {
        let url = `/api/search/specific-item/?id=${id}&destinationType=${destinationType}`;
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    availability = (date: Date, duration: number, airport: string, travelAreaId: number, passengers: [], flightClass: string, occasion: string, callbackSuccess: (any), callbackFail: Function): any => {
        let url = `/api/search/availability/?date=${date.toISOString()}&duration=${duration}&departureAirport=${airport}&travelAreaId=${travelAreaId}&cabinClass=${flightClass || ''}&occasion=${occasion || ''}&${this.passengerString(passengers)}`;
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);;
    }
    
    accommodationAvailability = (date: Date, duration: number, airport: string, accommodationId: number, passengers: [], flightClass: string, occasion: string, callbackSuccess: (any), callbackFail: Function): any => {
        let url = `/api/search/availability/?date=${date.toISOString()}&duration=${duration}&departureAirport=${airport}&accommodationId=${accommodationId}&cabinClass=${flightClass || ''}&occasion=${occasion || ''}&${this.passengerString(passengers)}`;
        baseService.setUpCallbacksAndRequest(callbackSuccess, callbackFail, url);
    }

    passengerString(passengerObjectArray: any[]) {
        let sb = '';
        for (var i = 0; i < passengerObjectArray.length; i++) {
            if(i > 0)
                sb += '&';

            let room = passengerObjectArray[i];

            sb += `passengerRooms[${i}].adults=${room.adults}`
            for (var j = 0; j < room.childAges.length; j++) {
                sb += `&passengerRooms[${i}].childAges[${j}]=${room.childAges[j]}`
            }            
        }
        return sb
    }

    releaseQs() {
        return 'r=' + (<HTMLInputElement>document.getElementById('releaseVersion')).value;
    }
}

export default new SearchService();