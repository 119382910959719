import { defineStore } from 'pinia'

export type RootState = {
    open: boolean;    
    footerActive: boolean
};

export const useCtaStore = defineStore("cta", {
    state: (): RootState => ({
        open: false,   
        footerActive: false     
    }),
    getters: {
    },
    actions: { 
        setOpen(shouldOpen: boolean) {
            this.footerActive = shouldOpen;
            this.open = shouldOpen;
        }
    },
})