<template>
    <component :is="Suspense">
    </component>
</template>

<script lang="ts">
import {Suspense as suspense_, VNodeProps, SuspenseProps, defineComponent} from 'vue';

export default defineComponent({
    name: 'App',
    setup() {
        const Suspense = suspense_ as {
            new (): {
                $props: VNodeProps & SuspenseProps
            }
        }

        return {
            Suspense,
        }
    },
});
</script>